import { Em3Event, EventReference } from '../models/event.model';

import {
  UIActions,
  START_LOADING,
  STOP_LOADING,
  SET_EVENTS,
  SET_SELECTED_EVENT,
  GET_USER_INFO_SUCCESS,
  REFRESH_EVENT_DETAILS_SUCCESS,
  START_PROCESSING,
  STOP_PROCESSING,
  SEND_ALERT,
  SET_EVENT_ALERT_SETTINGS,
  SET_LIGHT_EVENTS
} from './ui.actions';
import { IUserProfileModel } from '../models/userProfile.model';
import { createSelector, createFeatureSelector } from '@ngrx/store';

export interface State {
  isLoading: boolean;
  isProcessing: boolean;
  events: Em3Event[];
  lightEvents: Em3Event[];
  selectedEvent: Em3Event;
  userInfo: IUserProfileModel;
}

export function getUiInitialState(): State {
  return initialState;
}

const initialState: State = {
  isLoading: false,
  isProcessing: false,
  events: [],
  lightEvents: [],
  selectedEvent: null,
  userInfo: null
};

export function uiReducer(state = initialState, action: UIActions) {

  switch (action.type) {
    case START_LOADING:
      return {
        ...state,
        isLoading: true
      };
    case STOP_LOADING:
      return {
        ...state,
        isLoading: false
      };

    case START_PROCESSING:
      return {
        ...state,
        isProcessing: true
      };
    case SEND_ALERT:
      return {
        ...state,
        isProcessing: true
      };
    case STOP_PROCESSING:
      return {
        ...state,
        isProcessing: false
      };

    case SET_EVENTS:
      return {
        ...state,
        events: action.payload
      };
    case SET_LIGHT_EVENTS:
      return {
        ...state,
        lightEvents: action.payload
      };
    case GET_USER_INFO_SUCCESS:
      return {
        ...state,
        userInfo: action.payload
      };
    case SET_SELECTED_EVENT:
      return {
        ...state,
        selectedEvent: action.payload
      };

    case REFRESH_EVENT_DETAILS_SUCCESS:
      return {
        ...state,
        selectedEvent: action.payload
      };
    case SET_EVENT_ALERT_SETTINGS:
      return {
        ...state,
        selectedEvent: {
          ...state.selectedEvent,
          alertSettings: action.payload
        }
      };

    default: {
      return state;
    }
  }
}

export const getUiState = createFeatureSelector < State > ('ui');

export const getIsLoading = (state: State) => state.isLoading;
export const getIsProcessing = (state: State) => state.isProcessing;
export const getEvents = (state: State) => state.events;
export const getLightEvents = (state: State) => state.lightEvents;
export const getSelectedEvent = (state: State) => state.selectedEvent;

export const getEventAttributes = createSelector(getUiState, (state: State) => state.selectedEvent.attributes);
export const getEventRibbons = createSelector(getUiState, (state: State) => state.selectedEvent.ribbons);
export const getEventSessions = createSelector(getUiState, (state: State) => state.selectedEvent.sessions);
export const getEventQuestions = createSelector(getUiState, (state: State) => state.selectedEvent.questions);
export const getEventPrices = createSelector(getUiState, (state: State) => state.selectedEvent.prices);
export const getEventAddOns = createSelector(getUiState, (state: State) => state.selectedEvent.addOns);
export const getPartnerLevels = createSelector(getUiState, (state: State) => state.selectedEvent.partnerLevels);
export const getRegions = createSelector(getUiState, (state: State) => state.selectedEvent.regions);
export const getEventAlertSettings = createSelector(getUiState, (state: State) => state.selectedEvent == null ? null : state.selectedEvent.alertSettings);
export const getEventGroup = createSelector(getUiState, (state: State) => state.selectedEvent.group == null ? [{ eventId: state.selectedEvent.id, eventCode: state.selectedEvent.code}]: state.selectedEvent.group.events);

export const getUserInfo = (state: State) => state.userInfo;
