import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {environment} from "@ynet-environments/environment";


@Injectable()
export class PrintingService {


    constructor(private http: HttpClient) {
    }
    
    printAttendeeBadge(badgeId: string, stationId: string): any {
        return this.http.post(`${environment.api.baseUrl}/attendees/${badgeId}/badge/print?stationId=${stationId}`, null);
    }

    printAttendeeItinerary(badgeId: string, stationId: string): any {
        return this.http.post(`${environment.api.baseUrl}/attendees/${badgeId}/itinerary/print?stationId=${stationId}`, null);
    }


}
