import { Action } from '@ngrx/store';
import { Em3Event } from '../models/event.model';
import { ValidationError } from '../models/validation-error.model';
import { IUserProfileModel } from '../models/userProfile.model';
import { AlertModel } from '../models/alert.model';
import { EventAlertSettings } from '../../features/settings/models/eventAlertSettings.model';
import { EventGeneralSettings } from '../../features/settings/models/eventGeneralSettings.model';


export const START_LOADING = '[UI] Start Loading';
export class StartLoading implements Action {
  readonly type = START_LOADING;
}

export const STOP_LOADING = '[UI] Stop Loading';
export class StopLoading implements Action {
  readonly type = STOP_LOADING;
}

export const START_PROCESSING = '[UI] Start Processing';
export class StartProcessing implements Action {
  readonly type = START_PROCESSING;
}

export const STOP_PROCESSING = '[UI] Stopped Processing';
export class StopProcessing implements Action {
  readonly type = STOP_PROCESSING;
}

export const GET_USER_INFO = '[UI] Get User Info';
export class GetUserInfo implements Action {
  readonly type = GET_USER_INFO;
}

export const GET_USER_INFO_SUCCESS = '[UI] Get User Info Success';
export class GetUserInfoSuccess implements Action {
  readonly type = GET_USER_INFO_SUCCESS;

  constructor(public payload: IUserProfileModel) {}
}

export const GET_EVENTS = '[UI] Get Events';
export class GetEvents implements Action {
  readonly type = GET_EVENTS;
}

export const SET_EVENTS = '[UI] Set Events (List)';
export class SetEvents implements Action {
  readonly type = SET_EVENTS;

  constructor(public payload: Em3Event[]) {}
}

export const GET_LIGHT_EVENTS = '[UI] Get Light Events';
export class GetLightEvents implements Action {
  readonly type = GET_LIGHT_EVENTS;
}

export const SET_LIGHT_EVENTS = '[UI] Set Light Events (List)';
export class SetLightEvents implements Action {
  readonly type = SET_LIGHT_EVENTS;

  constructor(public payload: Em3Event[]) {}
}


export const GET_EVENT_ALERT_SETTINGS = '[UI] Get Event Alert Settings';
export class GetEventAlertSettings implements Action {
  readonly type = GET_EVENT_ALERT_SETTINGS;

  constructor() {}
}

export const SET_EVENT_ALERT_SETTINGS = '[UI] Set Event Alert Settings';
export class SetEventAlertSettings implements Action {
  readonly type = SET_EVENT_ALERT_SETTINGS;

  constructor(public payload: EventAlertSettings) {}
}

export const GLOBAL_ACTION_FAILURE = '[UI] Global Action Failure';
export class GlobalActionFailure implements Action {
  readonly type = GLOBAL_ACTION_FAILURE;

  constructor(public payload: string) {}
}

export const GLOBAL_ACTION_SUCCESS = '[UI] Global Action Success';
export class GlobalActionSuccess implements Action {
  readonly type = GLOBAL_ACTION_SUCCESS;

  constructor(public payload: string) {}
}

export const GLOBAL_ACTION_WARNING = '[UI] Global Action Warning';
export class GlobalActionWarning implements Action {
  readonly type = GLOBAL_ACTION_WARNING;

  constructor(public payload: string) {}
}

export const HANDLE_VALIDATION_ERROR = '[UI] Handle Validation Error'
export class HandleValidationError implements Action {
  readonly type = HANDLE_VALIDATION_ERROR;

  constructor(public payload: ValidationError) {}
}

export const SET_SELECTED_EVENT = '[UI] Set Selected Event';
export class SetSelectedEvent implements Action {
  readonly type = SET_SELECTED_EVENT;

  constructor(public payload: Em3Event) {}
}

export const CREATE_EVENT = '[UI] Create Event';
export class CreateEvent implements Action {
  readonly type = CREATE_EVENT;

  constructor(public payload: EventGeneralSettings) {}
}

export const REFRESH_EVENT_DETAILS = '[UI] Refresh Selected Event Details';
export class RefreshEventDetails implements Action {
  readonly type = REFRESH_EVENT_DETAILS;

  constructor() {}
}


export const REFRESH_EVENT_DETAILS_SUCCESS = '[UI] Refresh Selected Event Details Success';
export class RefreshEventDetailsSuccess implements Action {
  readonly type = REFRESH_EVENT_DETAILS_SUCCESS;

  constructor(public payload: Em3Event) {}
}

export const SEND_ALERT = '[UI] Send Alert';
export class SendAlert implements Action {
  readonly type = SEND_ALERT;

  constructor(public payload: AlertModel) {}
}

export const FIND_EVENT = '[UI] Find Event';
export class FindEventByCode implements Action {
  readonly type = FIND_EVENT;

  constructor(public payload: string) {}
}

export const LOAD_REG_BY_TABLET = '[UI] Load Registration by Tablet';
export class LoadRegistrationByTablet implements Action {
  readonly type = LOAD_REG_BY_TABLET;

  constructor(public payload: string) {}
}



export type UIActions = StartLoading | StopLoading | GetEvents | SetEvents | SetLightEvents | SetSelectedEvent | CreateEvent |
  HandleValidationError | RefreshEventDetailsSuccess | SendAlert | StartProcessing | StopProcessing |
  GlobalActionFailure | GlobalActionSuccess | GlobalActionWarning | GetUserInfoSuccess | SetEventAlertSettings;
