import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';

import { environment } from '../../../environments/environment';
import { IAuthResponse } from '../../shared/models/userProfile.model';

@Injectable()
export class UserClaimsGuard implements CanActivate {

    constructor(private _router: Router) { }
    // private isChrome: boolean = !!(<any>window).chrome && (!!(<any>window).chrome.webstore || !!(<any>window).chrome.runtime);


    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

        const _jwtHelperService = new JwtHelperService();
        const authData: IAuthResponse = JSON.parse(localStorage.getItem(environment.authCookieName)) || {};
        //If is not using Chrome they are redirected to login page which then displays the Chrome message
        if (authData && authData.access_token) {
            // if (this.isChrome && authData && authData.access_token) {

            const decodedToken = _jwtHelperService.decodeToken(authData.access_token);
            const expirationDate = _jwtHelperService.getTokenExpirationDate(authData.access_token);
            const isExpired = _jwtHelperService.isTokenExpired(authData.access_token);

            if (!isExpired) {

                return true;
            }
        }

        this._router.navigate(['login'], { queryParams: { returnUrl: state.url } });
        return false;
    }
}
