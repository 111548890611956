import { NgModule } from '@angular/core';
import 'select2';

export { Select2OptionData, Select2TemplateFunction } from '@ynet-core/select2/ng2-select2.interface';
import { Select2Component } from '@ynet-core/select2/ng2-select2.component';

export { Select2Component } from '@ynet-core/select2/ng2-select2.component';


@NgModule({
    declarations: [Select2Component],
    exports: [Select2Component]
})
export class Select2Module { }