import { Component, OnInit, ViewEncapsulation, AfterViewInit, OnDestroy, ViewChild } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { Em3Event } from '../../shared/models/event.model';
import * as fromRoot from '../../app.reducer';
import * as fromUI from '../../shared/store/ui.reducer';
import * as UI from '../../shared/store/ui.actions';
import { Router } from '@angular/router';
import { IUserProfileModel } from '../../shared/models/userProfile.model';
import { environment } from '../../../environments/environment';
import { takeUntil } from 'rxjs/operators';
// import * as Sentry from '@sentry/browser';
import { EventAlertSettings } from '../../features/settings/models/eventAlertSettings.model';
import { CreateEventModalComponent } from './create-event-modal/create-event-modal.component';
import { EditLocationModalComponent } from './edit-location-modal/edit-location-modal.component';
import { EventGeneralSettings } from '../../features/settings/models/eventGeneralSettings.model';
import { LoadRegistrationByTablet } from "../../shared/store/ui.actions";



declare let mLayout: any;



@Component({
    selector: 'app-header-nav',
    templateUrl: './header-nav.component.html',
    encapsulation: ViewEncapsulation.None,
}) export class HeaderNavComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('uxpCreateEventModal') uxpCreateEventModal: CreateEventModalComponent;
    @ViewChild('uxpEditLocationModal') uxpEditLocationModal: EditLocationModalComponent;


    events$: Observable<Em3Event[]>;
    eventSelected$: Observable<Em3Event>;
    userInfo$: Observable<IUserProfileModel>;
    location: string;
    isProcessing$: Observable<boolean>;
    isElectronApp: boolean;
    generalPrinter: any;
    badgePrinter: any;
    receiptPrinter: any;
    selectedTabletName: string = '';
    private ngUnsubscribe: Subject<void> = new Subject<void>();
    eventAlertSettings$: Observable<EventAlertSettings>;


    constructor(
        private store$: Store<fromRoot.State>,
        private _router: Router
    ) { }


    ngOnInit() {
        this.events$ = this.store$.select(fromRoot.getLightEvents);
        this.isProcessing$ = this.store$.select(fromRoot.getIsProcessing);
        this.eventSelected$ = this.store$.select(fromRoot.getSelectedEvent);
        this.eventSelected$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(event => {
            this.uxpCreateEventModal?.hide();
        });

        this.userInfo$ = this.store$.select(fromRoot.getUserInfo);
        this.userInfo$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(userInfo => {
            if (userInfo == null) {
                return;
            }
            // Sentry.configureScope((scope) => {
            //   scope.setUser({
            //     'username': userInfo.profile.login || null,
            //     'email': userInfo.profile.email || null
            //   });
            // });
        });

        this.location = localStorage.getItem('alert-location');

        this.eventAlertSettings$ = this.store$.select(fromUI.getEventAlertSettings)
        this.refreshEvents();
    }


    alertPanelShouldBeVisible(settings: EventAlertSettings) {
        if (settings == null) return false;
        return settings.callMediaEnabled || settings.callSecurityEnabled || settings.callHostEnabled;
    }


    ngAfterViewInit() {
        mLayout.initHeader();
    }


    showLocationEditor() {
        this.uxpEditLocationModal.show(this.location);
    }


    onNewLocationSubmitted(location: string) {
        this.location = location;
        localStorage.setItem('alert-location', this.location);
    }


    openCreateNewEvent() {
        this.uxpCreateEventModal.show();
    }


    onNewEventSubmitted(newEvent: EventGeneralSettings) {
        this.store$.dispatch(new UI.CreateEvent(newEvent));
    }


    refreshEvents(): void {
        this.store$.dispatch(new UI.GetLightEvents());
    }


    selectEvent(event: Em3Event): void {
        this.store$.dispatch(new UI.SetSelectedEvent(event));
    }


    logout(): void {
        localStorage.removeItem(environment.authCookieName);
        this._router.navigate(['login']);
    }


    callSecurity() {
        this.store$.dispatch(new UI.SendAlert({ alertType: 1, location: this.location }));
    }


    callMedia() {
        this.store$.dispatch(new UI.SendAlert({ alertType: 2, location: this.location }));
    }

    callHost() {
        this.store$.dispatch(new UI.SendAlert({ alertType: 3, location: this.location }));
    }


    findEventByCode() {
        const pastEventCode = (<any>document.getElementById('uxpPastEventCode')).value;
        this.store$.dispatch(new UI.FindEventByCode(pastEventCode));
    }


    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }


    loadLastCheckinNew(tablet) {
        this.selectedTabletName = tablet;
        this.store$.dispatch(new UI.LoadRegistrationByTablet(this.selectedTabletName));
    }


    loadLastCheckin() {
        if (this.selectedTabletName === '') return;
        this.store$.dispatch(new UI.LoadRegistrationByTablet(this.selectedTabletName));
    }
}
