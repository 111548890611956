import { Injectable, Component, Input, ViewEncapsulation, ComponentFactoryResolver, NgZone } from "@angular/core";
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ButtonOption } from "../../shared/models/lookups.model";
import { Helpers } from '@ynet-core/helpers';

@Component({
    selector: 'ngbd-modal-alert',
    template: `
    <div class="modal-header">
      <h4 class="modal-title" id="modal-title">{{headerText}}</h4>
      <button type="button" class="close" aria-describedby="modal-title" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p>{{bodyText}}</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-danger" (click)="modal.close('Ok click')">Ok</button>
    </div>
    `,
    encapsulation: ViewEncapsulation.None,
    styles: [`
      .red-backdrop {
        background-color: #fb7777;
        z-index: 1050 !important;
      }
      .dark-backdrop {
        background-color: #555;
        z-index: 1050 !important;
      }
    `]
})
export class CustomAlertModal {
    @Input() bodyText;
    @Input() headerText;
    constructor(public modal: NgbActiveModal) { }
}

@Component({
    selector: 'ngbd-modal-prompt',
    template: `
        <div class="modal-header">
        <h4 class="modal-title" id="modal-title">{{headerText}}</h4>
        <button type="button" class="close" aria-describedby="modal-title" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
        </div>
        <div class="modal-body">
            <p>{{bodyText}}</p>
            <div class="row form-group">
                <label class="col">{{promptLabel}}</label>
                <input type='text' ngbAutofocus class="form-control m-input" name="promptValue" [(ngModel)]="promptValue" maxLength="300" />
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel click')">Cancel</button>
            <button type="button" class="btn btn-danger" [disabled]="!((promptValue && requirePromptWord == null) || (requirePromptWord != null && requirePromptWord == promptValue))" (click)="modal.close(promptValue)">Ok</button>
        </div>
    `,
    encapsulation: ViewEncapsulation.None,
    styles: [`
    .red-backdrop {
      background-color: #fb7777;
      z-index: 1050 !important;
    }
    .dark-backdrop {
      background-color: #555;
      z-index: 1050 !important;
    }
  `]
})
export class CustomPromptModal {
    @Input() bodyText;
    @Input() headerText;
    @Input() promptLabel;
    @Input() promptValue: string;
    @Input() requirePromptWord: string;

    constructor(public modal: NgbActiveModal) { }
}

@Component({
    selector: 'ngbd-modal-confirm',
    template: `
        <div class="modal-header">
        <h4 class="modal-title" id="modal-title">{{headerText}}</h4>
        <button type="button" class="close" aria-describedby="modal-title" (click)="modal.close('No')">
            <span aria-hidden="true">&times;</span>
        </button>
        </div>
        <div class="modal-body">
            <p [innerHTML]=bodyText></p>
        </div>
        <div class="modal-footer">
            <button *ngIf="buttonOptions.optionalButton" type="button" class="btn left-align {{buttonOptions.optionalButton.cssClass}}" (click)="modal.close(buttonOptions.optionalButton.text)">{{buttonOptions.optionalButton.text}}</button>
            <button type="button" class="btn {{buttonOptions.yesButton.cssClass}}" (click)="modal.close('Yes')">{{buttonOptions.yesButton.text}}</button>
            <button type="button" class="btn {{buttonOptions.noButton.cssClass}}" (click)="modal.close('No')">{{buttonOptions.noButton.text}}</button>
        </div>
    `,
    encapsulation: ViewEncapsulation.None,
    styles: [`
      .red-backdrop {
        background-color: #fb7777;
        z-index: 1050 !important;
      }
      .dark-backdrop {
        background-color: #555;
        z-index: 1050 !important;
      }
      .modal-footer .left-align{margin-right: auto !important;}
    `]
})
export class CustomConfirmModal {
    @Input() bodyText;
    @Input() headerText;
    @Input() buttonOptions = {
        yesButton: { text: "Yes", cssClass: "btn-primary" },
        noButton: { text: "No", cssClass: "btn-secondary" },
        optionalButton: null
    };

    constructor(public modal: NgbActiveModal) { }
}

const MODALS = {
    simpleAlert: CustomAlertModal,
    simplePrompt: CustomPromptModal,
    simpleConfirm: CustomConfirmModal
    //autofocus: NgbdModalConfirmAutofocus
};

@Injectable()
export class CustomModalsService {

    constructor(private _modalService: NgbModal, private componentFactoryResolver: ComponentFactoryResolver, private _ngZone: NgZone) {
        //this.removeBootstrapEnforceFocus();      
    }

    public showAlert(message: string, title: string = 'Alert!') {
        //this.removeBootstrapEnforceFocus();
        this.componentFactoryResolver.resolveComponentFactory(MODALS.simpleAlert);
        let modalRef = this._modalService.open(MODALS.simpleAlert, { backdropClass: 'dark-backdrop' });
        modalRef.componentInstance.bodyText = message;
        modalRef.componentInstance.headerText = title;
    }

    public showPrompt(message: string, title: string = 'Alert!', promptLabel: string = "Type CONFIRM", requirePromptWord: string = null) {
        this.removeBootstrapEnforceFocus();
        this.componentFactoryResolver.resolveComponentFactory(MODALS.simplePrompt);
        let modalRef = this._modalService.open(MODALS.simplePrompt, { backdropClass: 'dark-backdrop' });
        modalRef.componentInstance.bodyText = message;
        modalRef.componentInstance.headerText = title;
        modalRef.componentInstance.promptLabel = promptLabel;
        modalRef.componentInstance.requirePromptWord = requirePromptWord;
        return modalRef.result;
    }

    public showConfirm(message: string, title: string = 'Please Confirm', buttonOptions?: { yesButton: ButtonOption, noButton: ButtonOption, optionalButton?: ButtonOption }) {
        //this.removeBootstrapEnforceFocus();
        this.componentFactoryResolver.resolveComponentFactory(MODALS.simpleConfirm);
        let modalRef = this._modalService.open(MODALS.simpleConfirm, { backdropClass: 'dark-backdrop' });
        if (buttonOptions) {
            modalRef.componentInstance.buttonOptions = buttonOptions;
        }
        modalRef.componentInstance.bodyText = message;
        modalRef.componentInstance.headerText = title;
        return modalRef.result;
    }

    removeBootstrapEnforceFocus() {
        this._ngZone.runOutsideAngular(() => {
            $(document).off('focusin.modal');
        });
    }
}