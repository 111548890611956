import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgModule, Optional, SkipSelf } from '@angular/core';

// Pipes
import { MomentPipe } from '@ynet-core/pipes/moment.pipe';
import { InitCapsPipe } from '@ynet-core/pipes/init-caps.pipe';
import { FilterPipe } from '@ynet-core/pipes/filter.pipe';
import { PhonePipe } from '@ynet-core/pipes/phone.pipe';

// Extensions
import '@ynet-core/extensions/string.extensions';

// Includes
// import './includes/rxjs-operators';

import { LayoutComponent } from '@ynet-core/layout/layout.component';
import { HeaderNavComponent } from '@ynet-core/header-nav/header-nav.component';
import { AsideNavComponent } from '@ynet-core/aside-nav/aside-nav.component';
import { FooterComponent } from '@ynet-core/footer/footer.component';
import { ScrollTopComponent } from '@ynet-core/scroll-top/scroll-top.component';
import { TooltipsComponent } from '@ynet-core/tooltips/tooltips.component';
import { HrefPreventDefaultDirective } from '@ynet-core/directives/href-prevent-default.directive';
import { UnwrapTagDirective } from '@ynet-core/directives/unwrap-tag.directive';

import { HasClaimDirective } from './directives/HasClaim.directive';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CreateEventModalComponent } from './header-nav/create-event-modal/create-event-modal.component';
import { EditLocationModalComponent } from './header-nav/edit-location-modal/edit-location-modal.component';


@NgModule({
    imports: [
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        RouterModule,
    ],
    declarations: [
        MomentPipe,
        InitCapsPipe,
        FilterPipe,
        PhonePipe,
        LayoutComponent,

        HeaderNavComponent,
        AsideNavComponent,
        FooterComponent,
        ScrollTopComponent,
        TooltipsComponent,
        HrefPreventDefaultDirective,
        UnwrapTagDirective,
        HasClaimDirective,
        CreateEventModalComponent,
        EditLocationModalComponent
    ],
    exports: [
        MomentPipe,
        InitCapsPipe,
        FilterPipe,
        PhonePipe,

        LayoutComponent,

        HeaderNavComponent,
        AsideNavComponent,
        FooterComponent,
        ScrollTopComponent,
        TooltipsComponent,
        HrefPreventDefaultDirective,
        HasClaimDirective
    ]
})

export class CoreModule {
    constructor(@Optional() @SkipSelf() parentModule: CoreModule) { }
}


