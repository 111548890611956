import { ActionReducerMap, createFeatureSelector, createSelector, MetaReducer, ActionReducer } from '@ngrx/store';
import { LocalStorageConfig, localStorageSync } from 'ngrx-store-localstorage';

import * as fromUi from './shared/store/ui.reducer';
// import { storeFreeze } from 'ngrx-store-freeze';
import { environment } from '@ynet-environments/environment';
import { RouterReducerState, routerReducer } from '@ngrx/router-store';



export interface State {
    // router: RouterReducerState;
    ui: fromUi.State;
}

export const reducers: ActionReducerMap<State> = {
    // router: routerReducer,
    ui: fromUi.uiReducer
};

export function logger(reducer: ActionReducer<State>): ActionReducer<State> {
    return function(state: State, action: any): State {
        // console.log('state', state);
        // console.log('action', action);
        return reducer(state, action);
    };
}


export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
    return localStorageSync(
        {
            keys: [
                { ui: ['selectedEvent', 'userInfo'] },
                { tables: ['selectedSession'] },
                { appointments: ['selectedCongressman'] }
            ],
            storageKeySerializer: (key) => 'em3_' + key,
            rehydrate: true,
            removeOnUndefined: true
        })(reducer);
}


export const metaReducers: MetaReducer<State>[] = !environment.production
    ? [localStorageSyncReducer]
    // ? [storeFreeze, localStorageSyncReducer]
    : [localStorageSyncReducer];

export const getUiState = createFeatureSelector<fromUi.State>('ui');

export const getIsLoading = createSelector(getUiState, fromUi.getIsLoading);
export const getIsProcessing = createSelector(getUiState, fromUi.getIsProcessing);
export const getEvents = createSelector(getUiState, fromUi.getEvents);
export const getLightEvents = createSelector(getUiState, fromUi.getLightEvents);
export const getSelectedEvent = createSelector(getUiState, fromUi.getSelectedEvent);
export const getUserInfo = createSelector(getUiState, fromUi.getUserInfo);
