import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, forkJoin } from 'rxjs';
import { Em3Event } from '../../shared/models/event.model';
import { environment } from '@ynet-environments/environment';
import { DataControlLookupItem } from '../../shared/models/lookups.model';
import { RegistrationSession } from '../../features/registration/models/registration-session.model';
import { RegistrationQuestion } from '../../features/registration/models/registration-questions.model';
import { RegistrationPriceModel } from '../../features/registration/models/registration-price.model';
import { RegistrationAddOnModel } from '../../features/registration/models/registration-addon.model';
import { SmartyStreetsSuggestionsResponse, SmartyStreetsSuggestion, SmartyStreetsFullAddress } from '../../features/registration/models/attendee-address.model';
import { EventAttribute } from '../../features/settings/models/eventAttribute.model';
import { EventSpeaker } from '../../shared/models/eventSpeaker.model';
import { EventRibbon } from '../../features/settings/models/eventRibbon.model';
import { EventAddOn } from '../../features/settings/models/eventAddOn.model';
import { CodeNamePair } from '../../features/registration/models/code-name-pair.model';
import { map } from 'rxjs/operators';
import { PartnerLevel } from '../../features/registration/models/partner-numbers.model';
import { NotificationMessage } from '../../features/notifications/models/notification-message.models';
import { EventGeneralSettings } from '../../features/settings/models/eventGeneralSettings.model';
import { EventAlertSettings } from '../../features/settings/models/eventAlertSettings.model';
import { PriceGroup } from '../../shared/models/price-groups.model';



@Injectable() export class EventService {

  getEventWithDetails(eventId: string): Observable < Em3Event > {
    const getEvent = this.http.get < Em3Event > (environment.api.baseUrl + `/admin/events/${eventId}`);
    const getEventQuestions = this.http.get < RegistrationQuestion[] > (environment.api.baseUrl + `/admin/events/${eventId}/questions`);
    const getEventPrices = this.http.get < RegistrationPriceModel[] > (environment.api.baseUrl + `/admin/events/${eventId}/prices`);
    const getEventAddOns = this.http.get < RegistrationAddOnModel[] > (environment.api.baseUrl + `/admin/events/${eventId}/options`);
    const getEventRibbons = this.http.get < EventRibbon[] > (environment.api.baseUrl + `/events/${eventId}/ribbons`);
    const getEventAttributes = this.http.get < EventAttribute[] > (environment.api.baseUrl + `/events/${eventId}/attributes`);
    const getPartnerLevels = this.http.get < PartnerLevel[] > (environment.api.baseUrl + `/events/${eventId}/partnerlevels`);
    const getMealPreferences = this.http.get < DataControlLookupItem[] > (environment.api.baseUrl + `/admin/meal-preferences`);
    const getTitles = this.http.get < string[] > (environment.api.baseUrl + `/settings/titles`);
    const getCountries = this.http.get < CodeNamePair[] > (environment.api.baseUrl + `/settings/countries`);
    const getRegions = this.http.get < number[] > (environment.api.baseUrl + `/admin/regions`);
    const getAlertSettings = this.http.get < EventAlertSettings > (environment.api.baseUrl + `/admin/events/${eventId}/alert-settings`);;

    return forkJoin(
      getEvent, getMealPreferences, getTitles, getCountries, getEventAddOns, getEventQuestions,
      getEventPrices, getEventAttributes, getEventRibbons, getPartnerLevels, getRegions, getAlertSettings
    ).pipe(
      map(results => {
        const event = < Em3Event > results[0];
        if (event) {
          event.mealPreferences = results[1];
          event.titles = results[2];
          event.countries = results[3];
          event.addOns = results[4];
          event.questions = results[5];
          event.prices = results[6];
          event.attributes = results[7];
          event.ribbons = results[8];
          event.partnerLevels = results[9];
          event.regions = results[10];
          event.alertSettings = results[11];
          return event;
        }
      })
    );
  }

  getCountries(): Observable < CodeNamePair[] > {
    return this.http.get < CodeNamePair[] > (environment.api.baseUrl + `/settings/countries`);
  }

  getCountryProvinces(countryCode: string): Observable < CodeNamePair[] > {
    return this.http.get < CodeNamePair[] > (environment.api.baseUrl + `/settings/countries/${countryCode}/provinces`);
  }

  getExcludedEventSpeakersForAdmin(eventId: string): Observable < EventSpeaker[] > {
    return this.http.get < EventSpeaker[] > (`${environment.api.baseUrl}/admin/events/${eventId}/speakers/excluded`);
  }

  getAllSpeakersForAdmin(): Observable < EventSpeaker[] > {
    return this.http.get < EventSpeaker[] > (`${environment.api.baseUrl}/admin/speakers`);
  }

  getEventSpeakersForAdmin(eventId: string): Observable < EventSpeaker[] > {
    return this.http.get < EventSpeaker[] > (`${environment.api.baseUrl}/admin/events/${eventId}/speakers`);
  }

  getAllPriceGroups(): Observable < PriceGroup[] > {
    return this.http.get < PriceGroup[] > (`${environment.api.baseUrl}/admin/price-groups`);
  }

  constructor(private http: HttpClient) {}

  getActiveEvents(): Observable < Em3Event[] > {
    return this.http.get < Em3Event[] > (environment.api.baseUrl + '/admin/events');
  }

  getLightEvents(): Observable < Em3Event[] > {
    return this.http.get < Em3Event[] > (environment.api.baseUrl + '/admin/light-events');
  }

  getEvent(eventId: string): Observable < Em3Event > {
    return this.http.get < Em3Event > (environment.api.baseUrl + `/events/${eventId}`);
  }

  findEventByCode(eventCode: string): Observable < Em3Event > {
    return this.http.post < Em3Event > (environment.api.baseUrl + '/admin/find-event', { code: eventCode });
  }

  getEventPrices(eventId: string): Observable < Em3Event > {
    return this.http.get < Em3Event > (environment.api.baseUrl + `/events/${eventId}/prices`);
  }

  getEventAttributes(eventId: string): Observable < DataControlLookupItem[] > {
    return this.http.get < DataControlLookupItem[] > (environment.api.baseUrl + `/events/${eventId}/attributes`);
  }

  getEventAttributesForAdmin(eventId: string, includeEventGroup: boolean = false): Observable < EventAttribute[] > {
    return this.http.get < EventAttribute[] > (environment.api.baseUrl + `/events/${eventId}/attributes?includeEventGroup=${includeEventGroup}`);
  }

  getEventRibbonsForAdmin(eventId: string, includeEventGroup: boolean = false): Observable < EventRibbon[] > {
      return this.http.get<EventRibbon[]>(environment.api.baseUrl + `/events/${eventId}/ribbons?includeEventGroup=${includeEventGroup}`);
  }

  getEventRibbons(eventId: string, includeEventGroup: boolean = false): Observable < DataControlLookupItem[] > {
      return this.http.get<DataControlLookupItem[]>(environment.api.baseUrl + `/events/${eventId}/ribbons?includeEventGroup=${includeEventGroup}`);
  }

  getEventPartnerLevels(eventId: string): Observable < DataControlLookupItem[] > {
    return this.http.get < DataControlLookupItem[] > (environment.api.baseUrl + `/events/${eventId}/partnerlevels`);
  }

  getEventSessionsForRegistration(eventId: string): Observable < RegistrationSession[] > {
    return this.http.get < RegistrationSession[] > (environment.api.baseUrl + `/events/${eventId}/sessions?includeFullSessionOptions=true`);
  }

  getEventQuestionsForRegistration(eventId: string): Observable < RegistrationQuestion[] > {
    return this.http.get < RegistrationQuestion[] > (environment.api.baseUrl + `/events/${eventId}/questions`);
  }

  getEventPricesForRegistration(eventId: string): Observable < RegistrationPriceModel[] > {
    return this.http.get < RegistrationPriceModel[] > (environment.api.baseUrl + `/admin/events/${eventId}/prices`);
  }

  getEventAddOnsForRegistration(eventId: string): Observable < RegistrationAddOnModel[] > {
    return this.http.get < RegistrationAddOnModel[] > (environment.api.baseUrl + `/admin/events/${eventId}/options`);
  }

  getEventAddOnsForSettings(eventId: string): Observable < EventAddOn[] > {
    return this.http.get < EventAddOn[] > (environment.api.baseUrl + `/admin/events/${eventId}/options`);
  }

  getEventNotifications(eventId: string): Observable < NotificationMessage[] > {
    return this.http.get < NotificationMessage[] > (environment.api.baseUrl + `/admin/events/${eventId}/notifications`);
  }

  saveEventNotification(eventId: string, payload: NotificationMessage): Observable < any > {
    return this.http.post < any > (environment.api.baseUrl + `/admin/events/${eventId}/notifications`, payload);
  }

  deleteEventNotification(eventId: string, messageId: number): Observable < any > {
    return this.http.delete < any > (environment.api.baseUrl + `/admin/events/${eventId}/notifications/${messageId}`);
  }

  sendEventNotification(eventId: string, messageId: number): Observable < any > {
    return this.http.post < any > (environment.api.baseUrl + `/admin/events/${eventId}/notifications/${messageId}/send`, null);
  }

  createEvent(payload: EventGeneralSettings): Observable < Em3Event > {
    return this.http.post < any > (environment.api.baseUrl + `/admin/events`, payload);
  }
}
