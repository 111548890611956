import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CoreModule } from '@ynet-core/core.module';
import { AuthHttpIntercepter } from '@ynet-core/handlers/AuthHttpIntercepter';
import { FieldErrorDisplayComponent } from './components/field-error-display.component';
import { CustomConfirmModal, CustomAlertModal, CustomPromptModal, CustomModalsService } from '../core/services/custom-modals.service';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { AuditModalComponent } from './components/audit-modal/audit-modal.component';
import { ReportService } from '../core/services/report.service';
import { LoginComponent } from './login/login.component';
import { TrustHtmlPipe } from './pipes/trust-html.pipe';
import { LoopFilterPipe } from './pipes/loop-filter.pipe';





@NgModule({
    imports: [
        CommonModule,
        // RouterModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        CoreModule,
        NgbModalModule
    ],
    declarations: [
        FieldErrorDisplayComponent,
        CustomAlertModal, CustomPromptModal, CustomConfirmModal,
        AuditModalComponent,
        LoginComponent,
        TrustHtmlPipe,
        LoopFilterPipe
    ],
    exports: [
        CommonModule,
        // RouterModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        CoreModule,
        FieldErrorDisplayComponent,
        CustomAlertModal, CustomPromptModal, CustomConfirmModal,
        AuditModalComponent,
        TrustHtmlPipe,
        LoopFilterPipe
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthHttpIntercepter,
            multi: true
        },
        CustomModalsService,
        ReportService
    ]
})
export class SharedModule { }
