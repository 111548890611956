import packageInfo from '../../package.json';

export const environment = {
    production: false,
    name: 'dev',
    reportErrors: false,
    api: {
        key: '77777772d445c84bb8bd8910c4f6b29e0698b421753e652151349c9498797075',
        baseUrl: 'https://dev-api-em3.cufi.org/v2',
    },
    smartyStreetSettings: {
        streetAddressApiUrl: 'https://us-street.api.smartystreets.com',
        autoCompleteApiUrl: 'https://us-autocomplete.api.smartystreets.com',
        clientAuthId: '25022836015240220',
        authId: '67bdf7e6-80d6-054c-adb9-317a6ea10cda',
        authToken: '7XDCfdtnHtIHHtgVGxfU',
    },
    authCookieName: '.IuNYworqKU',
    appVersion: packageInfo.version,
};
