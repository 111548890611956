<header class="m-grid__item    m-header" data-minimize-offset="200" data-minimize-mobile-offset="200" appunwraptag="">
	<div class="m-container m-container--fluid m-container--full-height">
		<div class="m-stack m-stack--ver m-stack--desktop">

			<div class="m-stack__item m-brand  m-brand--skin-dark">
				<div class="m-stack m-stack--ver m-stack--general">
					<div class="m-stack__item m-stack__item--middle m-brand__logo">
						<a routerLink="/index" class="m-brand__logo-wrapper">
							<img class="header-svg" alt="" width="160" src="./assets/img/logo-working_with-seal.svg" />
						</a>
					</div>

					<div class="m-stack__item m-stack__item--middle m-brand__tools">
						<a href="javascript:;" id="m_aside_left_minimize_toggle"
							class="m-brand__icon m-brand__toggler m-brand__toggler--left m--visible-desktop-inline-block">
							<span></span>
						</a>

						<a href="javascript:;" id="m_aside_left_offcanvas_toggle"
							class="m-brand__icon m-brand__toggler m-brand__toggler--left m--visible-tablet-and-mobile-inline-block">
							<span></span>
						</a>

						<a id="m_aside_header_menu_mobile_toggle" href="javascript:;"
							class="m-brand__icon m-brand__toggler m--visible-tablet-and-mobile-inline-block">
							<span></span>
						</a>

						<a id="m_aside_header_topbar_mobile_toggle" href="javascript:;"
							class="m-brand__icon m--visible-tablet-and-mobile-inline-block">
							<i class="flaticon-more"></i>
						</a>
					</div>
				</div>
			</div>

			<div class="m-stack__item m-stack__item--fluid m-header-head" id="m_header_nav">
				<button class="m-aside-header-menu-mobile-close m-aside-header-menu-mobile-close--skin-dark" id="m_aside_header_menu_mobile_close_btn">
					<i class="la la-close"></i>
				</button>

				<div id="m_header_menu" class="m-header-menu m-aside-header-menu-mobile m-aside-header-menu-mobile--offcanvas  m-header-menu--skin-light m-header-menu--submenu-skin-light m-aside-header-menu-mobile--skin-dark m-aside-header-menu-mobile--submenu-skin-dark">
					<ul class="m-menu__nav  m-menu__nav--submenu-arrow">

						<li class="m-menu__item  m-menu__item--submenu m-menu__item--rel"  data-menu-submenu-toggle="click" data-redirect="true" aria-haspopup="true">
							<a  href="#" class="m-menu__link m-menu__toggle" (click)="refreshEvents()">
								<i class="m-menu__link-icon flaticon-calendar"></i>
								<span class="m-menu__link-text">{{(eventSelected$ | async)?.name || "Select Event"}}</span>
								<i class="m-menu__hor-arrow la la-angle-down"></i>
								<i class="m-menu__ver-arrow la la-angle-right"></i>
							</a>

							<div class="m-menu__submenu m-menu__submenu--classic m-menu__submenu--left">
								<span class="m-menu__arrow m-menu__arrow--adjust"></span>
								<ul class="m-menu__subnav">
									<li *ngFor="let event of events$ | async" class="m-menu__item" aria-haspopup="true">
										<a href="#" class="m-menu__link" (click)="selectEvent(event)">
											<i class="m-menu__link-icon flaticon-calendar-3"></i>
											<span class="m-menu__link-text">
												{{event.name}}
											</span>
										</a>
									</li>
									<li *ngIf="(events$ | async).length < 1" class="m-menu__item" aria-haspopup="true">
										<span class="m-menu__link">
											No Events Found
										</span>
									</li>
								</ul>
							</div>
						</li>

						<li class="m-menu__item  m-dropdown m-dropdown--large m-dropdown--align-center m-dropdown--mobile-full-width m-dropdown--skin-light	m-list-search m-list-search--skin-light"  data-dropdown-toggle="click" data-dropdown-persistent="true" id="m_quicksearch" data-search-type="dropdown">
							<a  href="#" class="m-menu__link m-dropdown__toggle">
								<i class="m-menu__link-icon flaticon-search-1"></i>
								<span class="m-menu__link-text">Find Event</span>
								<i class="m-menu__hor-arrow la la-angle-down"></i>
								<i class="m-menu__ver-arrow la la-angle-right"></i>
							</a>
							<div class="m-dropdown__wrapper">
								<span class="m-dropdown__arrow m-dropdown__arrow--center"></span>
								<div class="m-dropdown__inner ">
									<div class="m-dropdown__header">
										<form (ngSubmit)="findEventByCode()" class="m-list-search__form" action="javascript:void(0);">
											<div class="m-list-search__form-wrapper">
												<span class="m-list-search__form-input-wrapper">
													<input id="uxpPastEventCode" autocomplete="off" type="text" class="m-list-search__form-input" value="" placeholder="Search Event Code...">
												</span>
												<span class="m-list-search__form-icon-close" id="m_quicksearch_close">
													<i class="la la-remove"></i>
												</span>
											</div>
										</form>
									</div>
								</div>
							</div>
						</li>

						<li class="m-menu__item">
							<a href="#" class="m-menu__link" (click)="openCreateNewEvent()">
								<i class="m-menu__link-icon flaticon-add"></i>
								<span class="m-menu__link-text">Create Event</span>
							</a>
						</li>

						<li class="m-menu__item  m-menu__item--submenu m-menu__item--rel"  data-menu-submenu-toggle="click" data-redirect="true" aria-haspopup="true">
							<a  href="#" class="m-menu__link m-menu__toggle" (click)="refreshEvents()">
								<i class="m-menu__link-icon flaticon-laptop"></i>
								<span class="m-menu__link-text">Tablets</span>
								<i class="m-menu__hor-arrow la la-angle-down"></i>
								<i class="m-menu__ver-arrow la la-angle-right"></i>
							</a>

							<div class="m-menu__submenu m-menu__submenu--classic m-menu__submenu--left">
								<span class="m-menu__arrow m-menu__arrow--adjust"></span>
								<ul class="m-menu__subnav">
									<li class="m-menu__item" aria-haspopup="true">
										<a href="#" class="m-menu__link" (click)="loadLastCheckinNew('CUFI-REG00')">
											<i class="m-menu__link-icon flaticon-download"></i>
											<span class="m-menu__link-text">CUFI-REG00</span>
										</a>
									</li>
									<li class="m-menu__item" aria-haspopup="true">
										<a href="#" class="m-menu__link" (click)="loadLastCheckinNew('CUFI-REG01')">
											<i class="m-menu__link-icon flaticon-download"></i>
											<span class="m-menu__link-text">CUFI-REG01</span>
										</a>
									</li>
									<li class="m-menu__item" aria-haspopup="true">
										<a href="#" class="m-menu__link" (click)="loadLastCheckinNew('CUFI-REG02')">
											<i class="m-menu__link-icon flaticon-download"></i>
											<span class="m-menu__link-text">CUFI-REG02</span>
										</a>
									</li>
									<li class="m-menu__item" aria-haspopup="true">
										<a href="#" class="m-menu__link" (click)="loadLastCheckinNew('CUFI-REG03')">
											<i class="m-menu__link-icon flaticon-download"></i>
											<span class="m-menu__link-text">CUFI-REG03</span>
										</a>
									</li>
									<li class="m-menu__item" aria-haspopup="true">
										<a href="#" class="m-menu__link" (click)="loadLastCheckinNew('CUFI-REG04')">
											<i class="m-menu__link-icon flaticon-download"></i>
											<span class="m-menu__link-text">CUFI-REG04</span>
										</a>
									</li>
								</ul>
							</div>
						</li>
					</ul>
				</div>
				<create-event-modal #uxpCreateEventModal (newEventSubmitted)="onNewEventSubmitted($event)"></create-event-modal>
				<edit-location-modal #uxpEditLocationModal (newLocationSubmitted)="onNewLocationSubmitted($event)"></edit-location-modal>





				<!-- BEGIN: Topbar -->
				<div id="m_header_topbar" class="m-topbar  m-stack m-stack--ver m-stack--general">
					<div class="m-stack__item m-topbar__nav-wrapper">
						<ul class="m-topbar__nav m-nav m-nav--inline">
							<li class="m-nav__item">
								<form class="form-inline mr-3" style="position: relative; top: 50%; transform: translateY(-50%);">
									<div>
										<a href="javascript:void(0);" (click)="showLocationEditor()" title="Click to change location">
											<i class="fa fa-edit"></i> <strong>Location: </strong> {{!!location ? location : 'N/A'}}&nbsp;
										</a>
									</div>
									<div [hidden]="(isProcessing$ | async) || !alertPanelShouldBeVisible((eventAlertSettings$|async))">
										<button type="button" [hidden]="(eventAlertSettings$ | async)?.callSecurityEnabled === false" [disabled]="location == null" (click)="callSecurity()" class="btn btn-danger ml-2">{{(eventAlertSettings$ | async)?.callSecurityButtonText || 'Call Security'}}</button>
										<button type="button" [hidden]="(eventAlertSettings$ | async)?.callMediaEnabled === false" [disabled]="location == null" (click)="callMedia()" class="btn btn-warning ml-2">{{(eventAlertSettings$ | async)?.callMediaButtonText || 'Call Media'}}</button>
										<button type="button" [hidden]="(eventAlertSettings$ | async)?.callHostEnabled === false" [disabled]="location == null" (click)="callHost()" class="btn btn-info ml-2">{{(eventAlertSettings$ | async)?.callHostButtonText || 'Call Host'}}</button>
									</div>

									<div [hidden]="!(isProcessing$ | async) || !alertPanelShouldBeVisible((eventAlertSettings$|async))" style="width: 234px;">
										<button type="button" [disabled]="true" class="btn btn-primary ml-2">Sending...<i class="fa fa-spinner fa-spin" style="font-size: 16px;color: white;margin-left: 10px;"></i></button>
									</div>
								</form>

							</li>


							<!-- Avatar Menu - Begin -->
							<li class="m-nav__item m-topbar__user-profile m-topbar__user-profile--img  m-dropdown m-dropdown--medium m-dropdown--arrow m-dropdown--header-bg-fill m-dropdown--align-right m-dropdown--mobile-full-width m-dropdown--skin-light"
								data-dropdown-toggle="click">
								<a href="#" class="m-nav__link m-dropdown__toggle">
									<span class="m-topbar__userpic">
										<div class="intials-circled border m--img-centered"
											style="width:40px; height: 40px; border-radius:20px;">
											{{(userInfo$ | async)?.userInitials}}
										</div>
									</span>
								</a>
								<div class="m-dropdown__wrapper" style="width:400px;">
									<span class="m-dropdown__arrow m-dropdown__arrow--right m-dropdown__arrow--adjust"
										style="color: #273268 !important;"></span>
									<div class="m-dropdown__inner">
										<div class="m-dropdown__header m--align-center"
											style="background: url(./assets/img/user_profile_bg_old.jpg); background-size: cover;">
											<div class="m-card-user m-card-user--skin-dark">
												<div class="m-card-user__pic" style="display:inherit; padding:unset;">
													<div class="intials-circled">
														{{(userInfo$ | async)?.userInitials}}
													</div>
												</div>
												<div class="m-card-user__details">
													<span class="m-card-user__name m--font-weight-500">
														{{(userInfo$ | async)?.profile?.firstName}}
														{{(userInfo$ | async)?.profile?.lastName}}
													</span>
													<a href="" class="m-card-user__email m--font-weight-300 m-link">
														{{(userInfo$ | async)?.profile?.email}}
													</a>
													<span class="m-card-user__email font-weight-bold"
														style="display:block;">
														{{(userInfo$ | async)?.groups}}
													</span>
												</div>
											</div>
										</div>
										<div class="m-dropdown__body">
											<div class="m-dropdown__content">
												<ul class="m-nav m-nav--skin-light">
													<li class="m-nav__section m--hide">
														<span class="m-nav__section-text">
															Section
														</span>
													</li>
													<li class="m-nav__separator m-nav__separator--fit"></li>
													<li class="m-nav__item">
														<a class="btn border m-btn--pill btn-light m-btn m-btn--custom m-btn--label-brand m-btn--bolder"
															(click)="logout()">
															Logout
														</a>
													</li>
												</ul>
											</div>
										</div>
									</div>
								</div>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
</header>
