import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { UserService } from '../../core/services/user.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../app.reducer';
import * as UI from '../../shared/store/ui.actions';

@Component({
    selector: 'login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    isSubmitting: boolean = false;
    formHasError: boolean = false;
    errorMessage: string;
    username: string;
    password: string;

    private ngUnsubscribe: Subject<void> = new Subject<void>();
    returnUrl: any;
    isIE: boolean = /MSIE 10/i.test(navigator.userAgent) || /MSIE 9/i.test(navigator.userAgent) || /rv:11.0/i.test(navigator.userAgent) || /Edge\/\d./i.test(navigator.userAgent);
    isChrome: boolean = !!(<any>window).chrome && (!!(<any>window).chrome.webstore || !!(<any>window).chrome.runtime) || (/Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor));


    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private _userService: UserService,
        private store$: Store<fromRoot.State>) { }

    ngOnInit() {

        // get return url from route parameters or default to '/'
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';

    }

    submitForm(form: UntypedFormGroup) {
        // console.log('submit form:', form);
        const formValues = form.value;
        this.errorMessage = '';
        this.formHasError = false;
        if (formValues.username === '') {
            this.errorMessage = 'Username is required';
        }

        if (formValues.password === '') {
            this.errorMessage = 'Password is required';
        }

        this.formHasError = form.invalid;
        if (this.formHasError) {
            return;
        }

        this.isSubmitting = true;

        this._userService.login(formValues.username, formValues.password)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(res => {
                // console.log('Login response:', res);
                localStorage.setItem(environment.authCookieName, JSON.stringify(res));
                this.store$.dispatch(new UI.GetUserInfo());
                this.router.navigateByUrl(this.returnUrl);
                this.isSubmitting = false;
            },
                error => {
                    // console.log('Error response:', error);
                    this.isSubmitting = false;
                    this.formHasError = true;
                    this.errorMessage = error.message;
                });
    }

}
