import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { FeatureComponent } from './feature.component';
import { UserClaimsGuard } from '../core/guards/user-claims.guard';

const routes: Routes = [
    {
        path: '',
        component: FeatureComponent,
        canActivate: [UserClaimsGuard],
        children: [
            {
                path: 'dashboard',
                loadChildren: () => import('../features/dashboard/dashboard.module').then(m => m.DashboardModule)
            },
            {
                path: 'admin',
                loadChildren: () => import('../features/admin/admin.module').then(m => m.AdminModule)
            },
            {
                path: 'tables',
                loadChildren: () => import('../features/tables/tables.module').then(m => m.TablesModule)
            },
            {
                path: 'reports',
                loadChildren: () => import('../features/reports/reports.module').then(m => m.ReportsModule)
            },
            {
                path: 'appointments',
                loadChildren:
                    () => import('../features/appointments/appointments.module').then(m => m.AppointmentsModule)
            },
            {
                path: 'settings',
                loadChildren: () => import('../features/settings/settings.module').then(m => m.SettingsModule)
            },
            {
                path: 'registration',
                loadChildren:
                    () => import('../features/registration/registration.module').then(m => m.RegistrationModule)
            },
            {
                path: 'speakers',
                loadChildren: () => import('../features/speakers/speakers.module').then(m => m.SpeakersModule)
            },
            {
                path: 'clubs',
                loadChildren: () => import('../features/clubs/clubs.module').then(m => m.ClubsModule)
            },
            {
                path: 'maps',
                loadChildren: () => import('../features/attendee-maps/attendee-maps.module').then(m => m.AttendeeMapsModule)
            },
            {
                path: 'notifications',
                loadChildren: () => import('../features/notifications/notifications.module').then(m => m.NotificationsModule)
            },
            {
                path: 'printer-settings',
                loadChildren: () => import('../features/printer-settings/printer-settings.module').then(m => m.PrinterSettingsModule)
            },
            {
                path: 'mobile-settings',
                loadChildren: () => import('../features/mobile-settings/mobile-settings.module').then(m => m.MobileSettingsModule)
            },
            {
                path: 'price-groups',
                loadChildren: () => import('../features/price-groups/price-groups.module').then(m => m.PriceGroupsModule)
            },
            {
                path: '',
                redirectTo: 'dashboard',
                pathMatch: 'full'
            }
        ]
    }/*,
    {
        path: '**',
        redirectTo: 'dashboard',
        pathMatch: 'full'
    }*/
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class FeatureRoutingModule { }
