import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Em3Event } from '../../shared/models/event.model';
import { environment } from '@ynet-environments/environment';
import { AllAttendee } from '../../shared/models/all-attendee.model';
import { Correspondence, CorrespondenceBatch, CorrespondenceBatchAttendee } from '../../shared/models/correspondence.model';
import { AuditEntry } from '../../shared/models/audit-entry.model';
import { Attendee } from '../../features/registration/models/registration.model';
import { share } from 'rxjs/operators';
import { CongressionalAppointment } from '../../shared/models/congressionalAppointment.model';
import { NewRegistrantCountByStateCD } from '../../shared/models/newRegistrantCountByStateCD.model';
import { EventSession } from '../../features/settings/models/eventSession.model';



@Injectable() export class ReportService {


  constructor(private http: HttpClient) {}


  getAttendeesByStatus(eventCode: string, statusId: any): Observable < AllAttendee[] > {
    return this.http.get < AllAttendee[] > (environment.api.baseUrl + `/events/${eventCode}/reports/all-attendees-by-status/${statusId}`)
  }


  getCorrespondences(eventId: string): Observable < Correspondence[] > {
    return this.http.get < Correspondence[] > (
      environment.api.baseUrl + `/admin/events/${eventId}/correspondences`
    );
  }

  getCongressionalAppointmentsReport(eventCode: string): Observable < CongressionalAppointment[] > {
    return this.http.get < CongressionalAppointment[] > (
      environment.api.baseUrl + `/reports/congressional-appointments/${eventCode}`
    );
  }

  getAttendeeAddonReport(eventId: string, from: string, to: string): Observable < any[] > {
    return this.http.get < any[] > (
      environment.api.baseUrl + `/events/${eventId}/reports/attendee-addon?from=${from}&to=${to}`
    );
  }

  getCongressionalAppointmentsByAttendeeReport(eventCode: string): Observable < any[] > {
    return this.http.get < any[] > (
      environment.api.baseUrl + `/reports/congressional-appointments-by-attendee/${eventCode}`
    );
  }


  getNewAttendeeCountByStateCDReport(eventCode: string): Observable < NewRegistrantCountByStateCD[] > {
    return this.http.get < NewRegistrantCountByStateCD[] > (
      environment.api.baseUrl + `/reports/new-attendee-by-state-cd/${eventCode}`
    );
  }


  getAttendeesByAttribute(eventId: string, attributeId: any): Observable < AllAttendee[] > {
    return this.http.get < AllAttendee[] > (environment.api.baseUrl + `/events/${eventId}/reports/all-attendees-by-attribute/${attributeId}`).pipe(share());
  }


  getAttendeesByRibbon(eventId: string, ribbonId: string): Observable < AllAttendee[] > {
    return this.http.get < AllAttendee[] > (environment.api.baseUrl + `/events/${eventId}/reports/all-attendees-by-ribbon/${ribbonId}`).pipe(share())
  }


  createCorrespondence(correspondence: Correspondence): Observable < Correspondence > {
    return this.http.post < Correspondence > (
      environment.api.baseUrl +
      `/admin/events/${correspondence.eventId}/correspondences`,
      correspondence
    );
  }


  createCorrespondenceBatch(
    eventId: string,
    correspondenceId: string
  ): Observable < any > {
    return this.http.post < any > (
      environment.api.baseUrl +
      `/admin/correspondences/${correspondenceId}/correspondence-batches`, null
    );
  }


  updateCorrespondenceBatchSentFlag(batch: CorrespondenceBatch): Observable < any > {
    return this.http.put < any > (
      environment.api.baseUrl +
      `/admin/correspondence-batches/${batch.correspondenceBatchId}`, batch
    );
  }


  getCorrespondenceBatches(
    correspondenceId: string,
    eventId: string
  ): Observable < CorrespondenceBatch[] > {
    return this.http.get < CorrespondenceBatch[] > (
      environment.api.baseUrl +
      `/admin/events/${eventId}/correspondences/${correspondenceId}/correspondence-batches`
    );
  }


  getCorrespondenceBatchRegistrants(
    batchId: string
  ): Observable < CorrespondenceBatchAttendee[] > {
    return this.http.get < CorrespondenceBatchAttendee[] > (
      environment.api.baseUrl +
      `/admin/correspondence-batches/${batchId}/attendees`
    );
  }


  getAllEventAttendees(eventId: string, includeExcluded: boolean): Observable < AllAttendee[] > {
    return this.http.get < AllAttendee[] > (
      environment.api.baseUrl + `/events/${eventId}/reports/all-attendees?includeExcluded=${includeExcluded}`
    );
  }


  runCorrespondanceBatchReport(eventCode: string, batchId: string): Observable < AllAttendee[] > {
    return this.http.get < AllAttendee[] > (environment.api.baseUrl + `/events/${eventCode}/reports/correspondance-batches/${batchId}`)
  }


  getAuditEntries(subjectType: string, subjectId: string): Observable < AuditEntry[] > {
    return this.http.get < AuditEntry[] > (
      environment.api.baseUrl + `/admin/audit/${subjectType}/${subjectId}`
    );
  }


  getAttendeesWithoutAttribute(eventId: string, attributeId: string, searchTerm: string): Observable < Attendee[] > {
    return this.http.post < Attendee[] > (environment.api.baseUrl + `/events/${eventId}/attendees-without-attribute`, { attributeId, searchTerm });
  }


  getAttendeesWithoutRibbon(eventId: string, ribbonId: string, searchTerm: string): Observable < Attendee[] > {
    return this.http.post < Attendee[] > (environment.api.baseUrl + `/events/${eventId}/attendees-without-ribbon`, { ribbonId, searchTerm });
  }


  getEvent(eventId: string): Observable < Em3Event > {
    return this.http.post < Em3Event > (environment.api.baseUrl + '/admin/find-event', { eventId });
  }


  getEventSessions(eventId: string): Observable < EventSession[] > {
    return this.http.get < EventSession[] > (environment.api.baseUrl + `/admin/events/${eventId}/sessions`);
  }


  getDistrictAttendeesCount(eventId: string): Observable < any > {
    return this.http.get < any[] > (environment.api.baseUrl + `/admin/events/${eventId}/district-attendees-count`);
  }


  getDistrictAttendeesCountForAppointments(eventId: string): Observable < any > {
    return this.http.get < any[] > (environment.api.baseUrl + `/admin/events/${eventId}/district-attendees-count-for-appointments`);
  }
}
