import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { EventGeneralSettings } from '../../../features/settings/models/eventGeneralSettings.model';



@Component({
    selector: 'create-event-modal',
    templateUrl: './create-event-modal.component.html',
    styles: []
}) export class CreateEventModalComponent implements OnInit {
    @Output() newEventSubmitted = new EventEmitter<EventGeneralSettings>();


    model: EventGeneralSettings = new EventGeneralSettings();


    constructor() { }
    ngOnInit() { }


    show() {
        this.model = new EventGeneralSettings();
        document.getElementById("uxpCreateEventModalButton").click();
    }


    hide() {
        document.getElementById("uxpCloseCreateEventModalButton").click();
    }


    submitForm() {
        this.newEventSubmitted.emit(this.model);
    }
}
