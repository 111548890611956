import { ErrorHandler, Injectable, Injector } from '@angular/core';
// import { LocationStrategy, PathLocationStrategy } from '@angular/common';

import { environment } from '../../../environments/environment';


//import * as Sentry from '@sentry/browser';

// Sentry.init({
//     dsn: 'https://f426f2c031c6455a9e172e4b7aa27670@sentry.io/1509241',
//     environment: environment.name,
//     release: environment.appVersion
// });



@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

    constructor(private injector: Injector) { }

    handleError(error: any): void {

        if (environment.reportErrors) {
            //const eventId = Sentry.captureException(error.originalError || error);
            // if (environment.production) {
            //     Sentry.showReportDialog({ eventId });
            // }
        }

        // const location = this.injector.get(LocationStrategy);
        // const message = error.message ? error.message : error._body.toString();
        // const url = location instanceof PathLocationStrategy
        //     ? location.path() : '';

        const apiError = this.parseError(error);
        const unwrappedError = error.originalError || error;

        if (!environment.production) {
            this.showErrorInConsole(unwrappedError);
            this.showApiErrorInConsole(apiError);
        }

        // Send Notification to user
        // notificationsService.notify('error', 'Error Log', 'testing');
        // setTimeout(() => notificationsService.notify('error', 'Error', apiError.message), 1);
    }

    private showApiErrorInConsole(apiError: ApiError): void {
        if (console && console.group && console.error) {
            console.group('Api Error Group');
            console.error(apiError);
            console.error(apiError.message);
            if (apiError.errors) {
                console.error(apiError.errors);
            }
            if (apiError.detail) {
                console.error(apiError.detail);
            }
            if (apiError.stack) {
                console.error(apiError.stack);
            }
            console.groupEnd();
        }
    }

    private showErrorInConsole(error: any): void {
        if (console && console.group && console.error) {
            console.group('Error Group');
            console.error(error);
            console.groupEnd();
        }
    }

    private parseError(error: any): ApiError {
        const unexpectedErrorMessage = 'Hmm.. Something went wrong. Try again shortly or call us at 210-477-4714';
        const statusCode = error.status ? error.status : 0;

        if (statusCode > 0) {
            let apiError = error.json() || {};
            if (apiError.Message) {
                return { message: apiError.Message, isError: true };
            }

            apiError = <ApiError>(error.json() || {});
            return apiError;
        }
        return { message: unexpectedErrorMessage, isError: true };
    }


}
export interface ApiError {
    message: string;
    isError: boolean;

    errors?: ValidationError[];

    detail?: string;
    stack?: string;
}

export interface ValidationError {
    errorMessage: string;
    controlName: string;
    errorCode: string;
}
