<button
  id="uxpEditLocationModalButton"
  type="button"
  [hidden]="true"
  data-toggle="modal"
  data-target="#uxpEditLocationModal">
  Show Modal
</button>



<div class="modal fade" id="uxpEditLocationModal" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <form class="payment-form" novalidate #newLocationForm="ngForm">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Edit Location</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">
              &times;
            </span>
          </button>
        </div>

        <div class="modal-body">
          <div class="m-section m-section--last">
            <div class="form-group m-form__group row">
              <div class="col-12">
                <input class="form-control m-input" type="text" [(ngModel)]="location" id="location" name="location" required>
              </div>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <button type="submit" class="btn btn-primary" [disabled]="!newLocationForm.valid" (click)="submitForm()">Update Location</button>
          <button type="button" id="uxpCloseEditLocationModalButton" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
        </div>
      </div>
    </form>
  </div>
</div>
