import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map, switchMap, tap, withLatestFrom, filter, catchError } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as fromUI from './ui.reducer';
import * as UIActions from './ui.actions';
import { ToastrService } from 'ngx-toastr';
import { Em3Event } from '../models/event.model';
import { EventService } from '../../core/services/event.service';
import { UserService } from '../../core/services/user.service';
import { of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { SettingsService } from '../../core/services/settings.service';
import { RegistrationService } from '../../core/services/registration.service.';

@Injectable()
export class UiEffects {
  constructor(
    private store$: Store < fromUI.State > ,
    private actions$: Actions,
    private _eventService: EventService,
    private _registrationService: RegistrationService,
    private _userService: UserService,
    private _settingsService: SettingsService,
    private _router: Router,
    private _toastr: ToastrService,
  ) {}

  onEffectFailed$ = createEffect(
    () =>
    this.actions$.pipe(
      ofType < UIActions.GlobalActionFailure > (UIActions.GLOBAL_ACTION_FAILURE),
      map((action) => action.payload),
      tap((x) => this._toastr.error(x)),
    ), { dispatch: false },
  );

  onEffectSuccess$ = createEffect(
    () =>
    this.actions$.pipe(
      ofType < UIActions.GlobalActionSuccess > (UIActions.GLOBAL_ACTION_SUCCESS),
      map((action) => action.payload),
      tap((x) => this._toastr.success(x)),
    ), { dispatch: false },
  );

  onEffectWarning$ = createEffect(
    () =>
    this.actions$.pipe(
      ofType < UIActions.GlobalActionWarning > (UIActions.GLOBAL_ACTION_WARNING),
      map((action) => action.payload),
      tap((x) => this._toastr.warning(x)),
    ), { dispatch: false },
  );

  getEvents$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UIActions.GET_EVENTS),
      switchMap(() =>
        this._eventService.getActiveEvents().pipe(map((events: Em3Event[]) => new UIActions.SetEvents(events))),
      ),
    ),
  );

  onGetLightEvents$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UIActions.GET_LIGHT_EVENTS),
      switchMap(() =>
        this._eventService.getLightEvents().pipe(map((events: Em3Event[]) => new UIActions.SetLightEvents(events))),
      ),
    ),
  );

  onFindEventByCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType < UIActions.FindEventByCode > (UIActions.FIND_EVENT),
      withLatestFrom(this.store$),
      switchMap(([action, state]) =>
        this._eventService.findEventByCode(action.payload).pipe(
          map((res) => {
            return new UIActions.SetSelectedEvent(res);
          }),
          catchError((err) => {
            return of(new UIActions.GlobalActionWarning(`Event was not found`));
          }),
        ),
      ),
    ),
  );

  onLoadRegistrationByTablet$ = createEffect(
    () =>
    this.actions$.pipe(
      ofType < UIActions.LoadRegistrationByTablet > (UIActions.LOAD_REG_BY_TABLET),
      withLatestFrom(this.store$),
      switchMap(([action, state]) =>
        this._registrationService.getLastCheckinByTablet(( < any > state).ui.selectedEvent.id, action.payload).pipe(
          tap((data) => {
            if (data && data.referenceNumber && data.badgeId) {
              this._router.navigate(['/registration/edit/', data.referenceNumber, data.badgeId]);
            }
          }),
        ),
      ),
    ), { dispatch: false },
  );

  onCreateEvent$ = createEffect(() =>
    this.actions$.pipe(
      ofType < UIActions.CreateEvent > (UIActions.CREATE_EVENT),
      withLatestFrom(this.store$),
      switchMap(([action, state]) =>
        this._eventService.createEvent(action.payload).pipe(
          switchMap((res) => [new UIActions.SetSelectedEvent(res)]),
          catchError((response) => {
            return of(
              response.status >= 400 && response.status < 500 && response.error ?
              new UIActions.GlobalActionWarning(response.error.message) :
              new UIActions.GlobalActionFailure('Unable to create new event'),
            );
          }),
        ),
      ),
    ),
  );

  onChangeSelectedEvent$ = createEffect(
    () =>
    this.actions$.pipe(
      ofType < UIActions.SetSelectedEvent > (UIActions.SET_SELECTED_EVENT),
      map((action) => action.payload),
      tap((event) => this._router.navigate(['/dashboard'], { queryParams: { eventId: event.id } })),
    ), { dispatch: false },
  );

  onRefreshSelectedEvent$ = createEffect(() =>
    this.actions$.pipe(
      ofType < UIActions.RefreshEventDetails > (UIActions.REFRESH_EVENT_DETAILS),
      withLatestFrom(this.store$),
      //filter(([action, state]) => state.selectedEvent != null),
      switchMap(([action, state]) => {
        // console.log('onRefreshSelectedEvent effect called!');
        return this._eventService
          .getEventWithDetails(( < any > state).ui.selectedEvent.id)
          .pipe(map((e: Em3Event) => new UIActions.RefreshEventDetailsSuccess(e)));
      }),
    ),
  );

  getUserInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UIActions.GET_USER_INFO),
      switchMap(() => this._userService.getUserInfo().pipe(map((res) => new UIActions.GetUserInfoSuccess(res)))),
    ),
  );

  onGetEventAlertSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UIActions.GET_EVENT_ALERT_SETTINGS),
      withLatestFrom(this.store$),
      filter(([action, state]) => ( < any > state).ui.selectedEvent.id != null),
      switchMap(([action, state]) =>
        this._settingsService.getEventAlertSettings(( < any > state).ui.selectedEvent.id).pipe(
          map((res) => {
            return new UIActions.SetEventAlertSettings(res);
          }),
        ),
      ),
    ),
  );

  onSendAlert$ = createEffect(() =>
    this.actions$.pipe(
      ofType < UIActions.SendAlert > (UIActions.SEND_ALERT),
      withLatestFrom(this.store$),
      //filter(([action, state]) => state.selectedEvent != null),
      switchMap(([action, state]) => {
        return this._userService.sendAlert(( < any > state).ui.selectedEvent.id, action.payload).pipe(
          switchMap((res) => [
            new UIActions.GlobalActionSuccess(`Alert message was successfully sent`),
            new UIActions.StopProcessing(),
          ]),
          catchError((response: HttpErrorResponse) =>
            of(
              new UIActions.StopProcessing(),
              response.status >= 400 && response.status < 500 && response.error ?
              new UIActions.GlobalActionWarning(response.error.message) :
              new UIActions.GlobalActionFailure(`Unable to send alert message`),
            ),
          ),
        );
      }),
    ),
  );
}
