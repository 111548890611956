<div class="m-page-loader m-page-loader--base m-page-loader--non-block" style="margin-left: -80px; margin-top: -20px;">
	<div class="m-blockui">
		<span>
			Please wait...
		</span>
		<span>
			<div class="m-loader m-loader--brand"></div>
		</span>
	</div>
</div>



<router-outlet></router-outlet>




<app-scroll-top></app-scroll-top>
