  <div class="text-center">
    <div class="login-bg-image"></div>

    <div>
      <div class="wrapper">
        <div class="console">
          <img style="width:480px" src="assets/img/logo-working_with-seal.svg">
        </div>
      </div>
    </div>

    <div class="container">

      <div class="justify-content-md-center row" *ngIf="isIE">
        <div class="col col-lg-2">
          &nbsp;
        </div>
        <div class="col-8 text-white text-left">
          <h3 >Sorry, your browser is not supported by this application. Please use Google Chrome to access this website...</h3>
          <br />
          <a class="text-warning" href="https://www.google.com/chrome/" target="_blank"><h4>Click here to install Google Chrome</h4></a>
        </div>
      </div>

      <div class="justify-content-md-center row" *ngIf="!isIE">
        <div class="col col-lg-2">
          &nbsp;
        </div>
        <div class="col-md-auto" style="width: 380px">
          <form (ngSubmit)="submitForm(loginForm);" #loginForm="ngForm">
            <div class="form-group m-0" [hidden]="!formHasError">
              <div class="alert alert-danger" role="alert">
                {{errorMessage}}
              </div>
            </div>
            <div class="form-group">
              <input type="text" class="form-control" placeholder="Username" name="username" [(ngModel)]="username"
                required aria-label="Username" autocomplete="off">
            </div>
            <div class="form-group">
              <input type="password" class="form-control" placeholder="Password" name="password" [(ngModel)]="password"
                required aria-label="Password" autocomplete="off">
            </div>
            <div class="form-group pt-3">
              <button [hidden]="isSubmitting" class="btn btn-info w-100" type="submit" data-type="save">Sign In</button>
              <button [hidden]="!isSubmitting" class="btn btn-info w-100" type="button" data-type="save"
                disabled>Signing In...<div class="m-loader m-loader--brand"></div></button>
            </div>
          </form>
        </div>
        <div class="col col-lg-2">
          &nbsp;
        </div>
      </div>

      <!-- <div data-se="auth-container" id="okta-sign-in" class="auth-container main-container can-remove-beacon">
        <div class="auth-content">
          <div class="auth-content-inner">
            <div class="primary-auth">
              <form method="POST" action="/signin/refresh-auth-state/00NilcpBdBwZ0fE8nm-d_8BOYJHDTISfJZSdNI6XQ0"
                data-se="o-form" slot="content" id="form31" class="primary-auth-form o-form o-form-edit-mode">
                <div data-se="o-form-content" class="o-form-content o-form-theme clearfix">
                  <div class="o-form-error-container" data-se="o-form-error-container"></div>
                  <div class="o-form-fieldset-container" data-se="o-form-fieldset-container">
                    <div data-se="o-form-fieldset" class="o-form-fieldset o-form-label-top">
                      <div data-se="o-form-input-container" class="o-form-input"><span data-se="o-form-input-username"
                          class="o-form-input-name-username o-form-control okta-form-input-field input-fix"> <span
                            class="input-tooltip icon form-help-16" data-hasqtip="0"></span> <span
                            class="icon input-icon person-16-gray"></span> <input type="text" placeholder="Username"
                            name="username" id="okta-signin-username" value="" aria-label="Username" autocomplete="off">
                        </span></div>
                    </div>
                    <div data-se="o-form-fieldset" class="o-form-fieldset o-form-label-top">
                      <div data-se="o-form-input-container" class="o-form-input"><span data-se="o-form-input-password"
                          class="o-form-input-name-password o-form-control okta-form-input-field input-fix"> <span
                            class="input-tooltip icon form-help-16" data-hasqtip="1"></span> <span
                            class="icon input-icon remote-lock-16"></span> <input type="password" placeholder="Password"
                            name="password" id="okta-signin-password" value="" aria-label="Password" autocomplete="off">
                        </span></div>
                    </div>
                    <div data-se="o-form-fieldset" class="o-form-fieldset o-form-label-top margin-btm-0">
                      <div data-se="o-form-input-container" class="o-form-input"><span data-se="o-form-input-remember"
                          class="o-form-input-name-remember">
                          <div class="custom-checkbox"><input type="checkbox" name="remember" id="input52"><label
                              for="input52" data-se-for-name="remember">Remember me</label></div>
                        </span></div>
                    </div>
                  </div>
                </div>
                <div class="o-form-button-bar"><input class="button button-primary" type="submit" value="Sign In"
                    id="okta-signin-submit" data-type="save"></div>
              </form>
              <div class="auth-footer"> <a href="#" data-se="needhelp" aria-expanded="false"
                  aria-controls="help-links-container" class="link help js-help"> Need help signing in? </a>
                <ul class="help-links js-help-links" id="help-links-container" style="display: none;">
                  <li> <a href="#" data-se="forgot-password" class="link js-forgot-password"> Forgot password? </a>
                  </li>
                  <li> <a href="https://accounts.cufi.org/help/login" data-se="help-link" class="link js-help-link"
                      target="_blank"> Help </a> </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div> -->

    </div>
  </div>
