import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';


import { environment } from '@ynet-environments/environment';
import { RegistrationSearchFilters } from '../../features/registration/models/registration-search-filters.model';
import { RegistrationSearchResult } from '../../features/registration/models/registration-search-result.model';
import { RegistrationModel, Attendee, RegistrationPaymentModel, RegistrationGroupModel } from '../../features/registration/models/registration.model';
import { CodeNamePair } from '../../features/registration/models/code-name-pair.model';
import { SessionPreference, RegistrationSessionOption } from '../../features/registration/models/registration-session.model';
import { DataControlLookupItem, RibbonLookupItem } from '../../shared/models/lookups.model';
import { RegistrationAnswer, RegistrationQuestion } from '../../features/registration/models/registration-questions.model';
import { PartnerNumberSearch, PartnerNumberSearchResult, AttendeePartnerInfo } from '../../features/registration/models/partner-numbers.model';
import { EventAttribute } from '../../features/settings/models/eventAttribute.model';
import { AttendeeSessionInfos } from '../../features/registration/models/attendee-session-infos.model';
import { RegistrationAddOnModel, UpdateAddOnModel } from '../../features/registration/models/registration-addon.model';
import { CreditCardValidationResponse } from '../../features/registration/models/creditcard-validation-response.model';
import { RegistrationPriceModel } from '../../features/registration/models/registration-price.model';
import { TransactionCredit } from '../../features/registration/models/transaction-models.model';
import { LastSelfCheckinAttempt } from "../../features/registration/models/last-self-checkin-attempt.model";
import { AttendeeBadgeTags } from '../../features/registration/models/attendee-printing.model';



@Injectable()
export class RegistrationService {



    constructor(private http: HttpClient) { }

    searchRegistrations(eventCode: string, filters: RegistrationSearchFilters): Observable<RegistrationSearchResult[]> {
        return this.http.post<RegistrationSearchResult[]>(environment.api.baseUrl + `/events/${eventCode}/registrations/search`, filters);
    }

    getRegistration(referenceNumber: string): Observable<RegistrationModel> {
        return this.http.get<RegistrationModel>(environment.api.baseUrl + `/registrations/${referenceNumber}`);
    }

    getAttendee(badgeId: string): Observable<Attendee> {
        return this.http.get<Attendee>(environment.api.baseUrl + `/attendees/${badgeId}`);
    }

    getAttendeePrimaryInfo(badgeId: string): Observable<Attendee> {
        return this.http.get<Attendee>(environment.api.baseUrl + `/attendees/${badgeId}/primary-info`);
    }





    searchPartnerNumbers(payload: PartnerNumberSearch): Observable<PartnerNumberSearchResult[]> {
        return this.http.post<PartnerNumberSearchResult[]>(environment.api.baseUrl + `/partners/search`, payload);
    }
    create(eventCode: string): Observable<RegistrationModel> {
        return this.http.post<RegistrationModel>(environment.api.baseUrl + `/registrations`, { eventCode });
    }


    addPartnerNumberToAttendee(referenceNumber: string, partnerNumber: number): any {
        return this.http.post(`${environment.api.baseUrl}/attendees/${referenceNumber}/partners/${partnerNumber}`, null);
    }

    deletePartnerNumberFromAttendee(referenceNumber: string, partnerNumber: number): any {
        return this.http.delete(`${environment.api.baseUrl}/attendees/${referenceNumber}/partners/${partnerNumber}`);
    }









    saveSessionOptionInvites(badgeId: string, payload: RegistrationSessionOption) {
        return this.http.post<any>(
            environment.api.baseUrl + `/attendees/${badgeId}/sessions/${payload.sessionId}/options/${payload.id}/invites`,
            payload.invites
        );
    }

    saveAttendeePartnerLevel(badgeId: string, levelCode: string) {
        return this.http.put<any>(
            environment.api.baseUrl + `/attendees/${badgeId}/partner-level`,
            { referenceNumber: badgeId, partnerLevelCode: levelCode }
        );
    }


    cancelAttendee(referenceNumber: string, badgeId: string, reason: string, confirmText: string): Observable<RegistrationModel> {
        return this.http.post<RegistrationModel>(`${environment.api.baseUrl}/admin/registrations/${referenceNumber}/attendees/${badgeId}/cancel`, { reason },
            confirmText == null ? {} : { headers: { 'CONFIRM-DELETE': confirmText } });
    }

    reissueAttendeeBadge(registrationReferenceNumber: string, badgeId: string, confirmText: string): Observable<RegistrationModel> {
        return this.http.put<RegistrationModel>(environment.api.baseUrl + `/registration/${registrationReferenceNumber}/attendees/${badgeId}/new-badge`, {},
            confirmText == null ? {} : { headers: { 'CONFIRM-DELETE': confirmText } });
    }

    cancelRegistration(referenceNumber: string, reason: string, confirmText: string): Observable<RegistrationModel> {
        return this.http.post<RegistrationModel>(`${environment.api.baseUrl}/admin/registrations/${referenceNumber}`, { reason },
            confirmText == null ? {} : { headers: { 'CONFIRM-DELETE': confirmText } });
    }

    resendAttendeeConfirmation(registrationReferenceNumber: string, badgeId: string): any {
        return this.http.post(environment.api.baseUrl + `/registration/${registrationReferenceNumber}/attendees/${badgeId}/resend-confirmation`, {});
    }

    getAttendeePartnershipInfo(badgeId: string, refresh: boolean = false): Observable<AttendeePartnerInfo> {
        return this.http.get<AttendeePartnerInfo>(environment.api.baseUrl + `/attendees/${badgeId}/partnerInfos?refresh=${refresh}`);
    }

    getPersonTitles(): Observable<string[]> {
        return this.http.get<string[]>(environment.api.baseUrl + `/settings/titles`);
    }

    getMealPreferences(): Observable<DataControlLookupItem[]> {
        return this.http.get<DataControlLookupItem[]>(environment.api.baseUrl + `/admin/meal-preferences`);
    }

    getCountries(): Observable<CodeNamePair[]> {
        return this.http.get<CodeNamePair[]>(environment.api.baseUrl + `/settings/countries`);
    }

    getCountryProvinces(countryCode: string): Observable<CodeNamePair[]> {
        return this.http.get<CodeNamePair[]>(environment.api.baseUrl + `/settings/countries/${countryCode}/provinces`);
    }

    getLastCheckinByTablet(eventId: string, location: string): Observable<LastSelfCheckinAttempt> {
        return this.http.get<LastSelfCheckinAttempt>(environment.api.baseUrl + `/events/${eventId}/checkin-attempts/${location}`)
    }

    updateAttendeePrimaryInfo(eventId: string, payload: Attendee): Observable<Attendee> {
        if (payload.referenceNumber == null || payload.referenceNumber === '') {
            return this.http.post<Attendee>(environment.api.baseUrl + `/events/${eventId}/registrations/${payload.registrationReferenceNumber}/attendees`, payload);
        }
        return this.http.put<Attendee>(environment.api.baseUrl + `/attendees/${payload.referenceNumber}/primary-info`, payload);
    }

    saveAttendeeSessionPreference(badgeId: string, payload: SessionPreference): Observable<Date> {
        return this.http.post<Date>(environment.api.baseUrl + `/attendees/${badgeId}/session-preference`, payload);
    }

    //TODO:  NEED TO DO
    saveAttendeeRsvpAnswers(badgeId: string, payload: any): any {
        return this.http.post<Date>(environment.api.baseUrl + `/attendees/${badgeId}/rsvps`, payload);
    }


    getAttendeeSessions(badgeId: string): Observable<SessionPreference[]> {
        return this.http.get<SessionPreference[]>(environment.api.baseUrl + `/attendees/${badgeId}/sessions`);
    }

    getExtensibleSessions(eventId: string, badgeId: string): Observable<any> {
        return this.http.get<SessionPreference[]>(environment.api.baseUrl + `/events/${eventId}/attendees/${badgeId}/extensibleSessions`);
    }

    getAttendeeAnswers(badgeId: string): Observable<RegistrationAnswer[]> {
        return this.http.get<RegistrationAnswer[]>(environment.api.baseUrl + `/attendees/${badgeId}/answers`);
    }

    saveAttendeeAnswer(badgeId: string, payload: RegistrationQuestion): Observable<RegistrationAnswer[]> {
        return this.http.post<RegistrationAnswer[]>(environment.api.baseUrl + `/attendees/${badgeId}/answers`, payload);
    }

    saveAttendeePrice(badgeId: string, price: RegistrationPriceModel): any {
        return this.http.put<Date>(environment.api.baseUrl + `/attendees/${badgeId}/prices`, price,
            price.confirmText == null ? {} : { headers: { 'CONFIRM-DELETE': price.confirmText } });
    }

    getAttendeeCandidatesForInvite(eventCode: string, badgeId: string, sessionOptionId: string, searchTerm: string): Observable<Attendee[]> {
        return this.http.post<Attendee[]>(environment.api.baseUrl + `/events/${eventCode}/attendees/${badgeId}/invite-candidates`, { sessionOptionId, searchTerm });
    }


    getAllAttributes(eventCode: string): Observable<EventAttribute[]> {
        return this.http.get<EventAttribute[]>(environment.api.baseUrl + `/events/${eventCode}/attributes/all`);
    }



    getAttendeeAttributes(badgeId: string): Observable<string[]> {
        return this.http.get<string[]>(environment.api.baseUrl + `/attendees/${badgeId}/attributes`);
    }

    saveAttendeeAttributes(badgeId: string, payload: string[]): Observable<string[]> {
        return this.http.post<string[]>(environment.api.baseUrl + `/attendees/${badgeId}/attributes`, payload);
    }


    getAttendeeRibbons(badgeId: string): Observable<RibbonLookupItem[]> {
        return this.http.get<RibbonLookupItem[]>(environment.api.baseUrl + `/attendees/${badgeId}/ribbons`);
    }

    getAttendeeBadgeTags(badgeId: string): Observable<AttendeeBadgeTags> {
        return this.http.get<AttendeeBadgeTags>(environment.api.baseUrl + `/attendees/${badgeId}/badge/tags`);
    }

    saveAttendeeRibbons(badgeId: string, payload: string[]): Observable<DataControlLookupItem[]> {
        return this.http.post<DataControlLookupItem[]>(environment.api.baseUrl + `/attendees/${badgeId}/ribbons`, { ribbonIds: payload });
    }



    getAttendeeAddOns(badgeId: string): Observable<string[]> {
        return this.http.get<string[]>(environment.api.baseUrl + `/attendees/${badgeId}/add-ons`);
    }

    saveAttendeeAddOns(badgeId: string, payload: string[]): Observable<string[]> {
        return this.http.post<string[]>(environment.api.baseUrl + `/attendees/${badgeId}/add-ons`, { addonIds: payload });
    }




    // TODO:  Need to create return object
    getAttendeeSessionInfos(badgeId: string): Observable<AttendeeSessionInfos> {
        return this.http.get<AttendeeSessionInfos>(
            environment.api.baseUrl + `/attendees/${badgeId}/sessionInfos`);
    }


    processPayment(payload: RegistrationPaymentModel): Observable<RegistrationModel> {
        return this.http.put<RegistrationModel>(
            environment.api.baseUrl + `/admin/registrations/${payload.referenceNumber}/complete`, payload);
    }

    validateCreditCard(payload: RegistrationPaymentModel): Observable<CreditCardValidationResponse> {
        return this.http.post<CreditCardValidationResponse>(
            environment.api.baseUrl + `/admin/registrations/${payload.referenceNumber}/validate-card`, payload.payment);
    }

    addGroup(referenceNumber: string, payload: RegistrationGroupModel): Observable<any> {
        payload.referenceNumber = referenceNumber;
        return this.http.post<RegistrationModel>(
            environment.api.baseUrl + `/admin/registrations/${referenceNumber}/group`, payload);
    }

    checkInAttendee(referenceNumber: string): Observable<any> {
        return this.http.post<any>(environment.api.baseUrl + `/attendees/${referenceNumber}/checkin`, {});
    }

    markAttendeeBadgeAsPrinted(referenceNumber: string): Observable<any> {
        return this.http.put<any>(environment.api.baseUrl + `/attendees/${referenceNumber}/mark-badge-as-printed`, {});
    }

    getBadgeHtml(referenceNumber: string): Observable<string> {
        return this.http.get<string>(environment.api.baseUrl + `/attendees/${referenceNumber}/badgeHtml`);
    }
    getItineraryHtml(referenceNumber: string): Observable<string> {
        return this.http.get<string>(environment.api.baseUrl + `/attendees/${referenceNumber}/itineraryHtml`);
    }

    saveAttendeeAddOn(badgeId: string, payload: UpdateAddOnModel) {
        return this.http.post<string[]>(`${environment.api.baseUrl}/attendees/${badgeId}/add-on`, { addOn: payload },
            payload.confirmText == null ? {} : { headers: { 'CONFIRM-DELETE': payload.confirmText } });
    }

    refundCredits(referenceNumber: string, payload: TransactionCredit[]) {
        return this.http.post<any>(`${environment.api.baseUrl}/admin/registrations/${referenceNumber}/refund`, { credits: payload })/*,
        payload.confirmText == null ? {} : { headers: { 'CONFIRM-DELETE': payload.confirmText } });*/
    }
}
