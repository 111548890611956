import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../../environments/environment';

import { EventQuestion } from '../../features/settings/models/eventQuestion.model';
import { Em3Event } from '../../shared/models/event.model';
import { EventPrice } from '../../features/settings/models/eventPrice.model';
import { EventQuestionType } from '../../features/settings/models/eventQuestionType.model';
import { EventSession } from '../../features/settings/models/eventSession.model';
import { EventGeneralSettings } from '../../features/settings/models/eventGeneralSettings.model';
import { EventSessionOption } from '../../features/settings/models/eventSessionOption.model';
import { EventAttribute } from '../../features/settings/models/eventAttribute.model';
import { EventSpeaker } from '../../shared/models/eventSpeaker.model';
import { EventRibbon } from '../../features/settings/models/eventRibbon.model';
import { EventAddOn } from '../../features/settings/models/eventAddOn.model';
import { StateOfResidence } from '../../features/settings/models/stateOfResidence.model';
import { MobileSettings, AppColorTheme, MenuItem } from '../../features/mobile-settings/models/mobile-settings.model';
import { EventAlertSettings, AlertRecipient } from '../../features/settings/models/eventAlertSettings.model';
import { PriceGroup } from '../../shared/models/price-groups.model';

@Injectable()
export class SettingsService {



    deleteMobileSettingsMenuItem(eventId: string, menuItemId: number) {
        return this.http.delete<any>(environment.api.baseUrl + `/events/${eventId}/settings/mobile/menu-item/${menuItemId}`);
    }

    updateMobileSettingsMenuItemPosition(eventId: string, payload: MenuItem) {
        return this.http.put<any>(environment.api.baseUrl + `/events/${eventId}/settings/mobile/menu-item/position`,
            {
                menuItemId: payload.id,
                newPosition: payload.position
            });
    }
    saveMobileSettingsMenuItem(eventId: string, payload: MenuItem) {
        return this.http.post<any>(environment.api.baseUrl + `/events/${eventId}/settings/mobile/menu-item`, payload);
    }
    saveMobileSettingsTheme(eventId: string, payload: AppColorTheme) {
        return this.http.put<any>(environment.api.baseUrl + `/events/${eventId}/settings/mobile/theme`, payload);
    }
    getMobileSettings(eventId: string): Observable<MobileSettings> {
        return this.http.get<MobileSettings>(environment.api.baseUrl + `/events/${eventId}/settings/mobile`);
    }
    processBulkRegistration(eventId: string, payload: any): Observable<any> {
        return this.http.post<any>(environment.api.baseUrl + `/admin/events/${eventId}/bulk-registration`, payload);
    }

    updateEventAddresses(eventId: string): Observable<any> {
        return this.http.post<any>(environment.api.baseUrl + `/admin/events/${eventId}/update-addresses`, JSON.stringify(eventId));
    }

    getRegions(): Observable<number[]> {
        return this.http.get<number[]>(environment.api.baseUrl + `/admin/regions`);
    }

    getStatesOfResidence(): Observable<StateOfResidence[]> {
        return this.http.get<StateOfResidence[]>(environment.api.baseUrl + `/admin/states`);
    }


    constructor(private http: HttpClient) { }

    //#region GET Methods

    getEventQuestionTypes(): Observable<EventQuestionType[]> {
        return this.http.get<EventQuestionType[]>(environment.api.baseUrl + `/events/questionTypes`);
    }

    getEventQuestions(eventId: string): Observable<EventQuestion[]> {
        return this.http.get<EventQuestion[]>(environment.api.baseUrl + `/admin/events/${eventId}/questions`);
    }

    getEventAlertSettings(eventId: string): Observable<EventAlertSettings> {
        return this.http.get<EventAlertSettings>(environment.api.baseUrl + `/admin/events/${eventId}/alert-settings`);
    }

    getEventPrices(eventId: string): Observable<EventPrice[]> {
        return this.http.get<EventPrice[]>(environment.api.baseUrl + `/admin/events/${eventId}/prices`);
    }

    getEventSessions(eventId: string): Observable<EventSession[]> {
        return this.http.get<EventSession[]>(environment.api.baseUrl + `/admin/events/${eventId}/sessions`);
    }

    getEventSession(sessionId: string): Observable<EventSession> {
        return this.http.get<EventSession>(environment.api.baseUrl + `/admin/sessions/${sessionId}`);
    }

    getEventSettings(eventId: string): Observable<EventGeneralSettings> {
        return this.http.get<EventGeneralSettings>(environment.api.baseUrl + `/admin/events/${eventId}/settings`);
    }

    getSessionOptions(sessionId: string): Observable<EventSessionOption[]> {
        return this.http.get<EventSessionOption[]>(environment.api.baseUrl + `/admin/sessions/${sessionId}/options`);
    }

    //#endregion

    //#region  POST Methods

    updateSelectedEvent(eventId: string, attendeeIds: string[]): Observable<Em3Event> {
        return this.http.post<Em3Event>(environment.api.baseUrl + `/events/${eventId}/`, JSON.stringify(attendeeIds));
    }

    updateEventQuestion(eventId: string, eventQuestion: EventQuestion, confirmText: any): Observable<EventQuestion> {
        return this.http.post<EventQuestion>(environment.api.baseUrl + `/admin/events/${eventId}/questions`, eventQuestion,
            confirmText == null ? {} : { headers: { 'CONFIRM-DELETE': confirmText } });
    }

    updateEventPrice(eventId: string, eventPrice: EventPrice): Observable<EventPrice> {
        return this.http.post<EventPrice>(environment.api.baseUrl + `/admin/events/${eventId}/prices`, JSON.stringify(eventPrice));
    }

    updateEventSession(eventId: string, eventSession: EventSession): Observable<EventSession> {
        return this.http.post<EventSession>(environment.api.baseUrl + `/admin/events/${eventId}/sessions`, JSON.stringify(eventSession));
    }

    updateEventGeneralSettings(eventId: string, eventGeneralSettings: EventGeneralSettings): Observable<EventGeneralSettings> {
        return this.http.post<EventGeneralSettings>(environment.api.baseUrl +
            `/admin/events/${eventId}/settings`, JSON.stringify(eventGeneralSettings));
    }

    updateSessionOption(sessionOption: EventSessionOption): Observable<EventSessionOption[]> {
        return this.http.post<EventSessionOption[]>(environment.api.baseUrl + `/admin/sessions/${sessionOption.sessionId}/options`, JSON.stringify(sessionOption));
    }

    updateEventAttribute(eventId: string, attribute: EventAttribute): any {
        return this.http.post<EventPrice>(environment.api.baseUrl + `/admin/events/${eventId}/attributes`, JSON.stringify(attribute));
    }

    deleteSession(sessionId: any, confirmText: any) {
        return this.http.delete(environment.api.baseUrl + `/admin/sessions/${sessionId}`,
            confirmText == null ? {} : { headers: { 'CONFIRM-DELETE': confirmText } });
    }

    deleteEventQuestion(eventId: string, questionId: string, confirmText: string) {
        return this.http.delete(environment.api.baseUrl + `/admin/events/${eventId}/questions/${questionId}`,
            confirmText == null ? {} : { headers: { 'CONFIRM-DELETE': confirmText } });
    }

    deleteSessionOption(sessionId: string, sessionOptionId: string, confirmText: string): Observable<EventSessionOption[]> {
        return this.http.delete<EventSessionOption[]>(environment.api.baseUrl + `/admin/sessions/${sessionId}/options/${sessionOptionId}`,
            confirmText == null ? {} : { headers: { 'CONFIRM-DELETE': confirmText } });
    }


    deleteEventPrice(eventId: string, priceId: string): any {
        return this.http.delete<EventSessionOption[]>(environment.api.baseUrl + `/admin/events/${eventId}/prices/${priceId}`,
            { headers: { 'CONFIRM-DELETE': "CONFIRM" } });
    }

    deleteEventAttribute(eventId: string, attributeId: string): any {
        return this.http.delete(environment.api.baseUrl + `/admin/events/${eventId}/attributes/${attributeId}`,
            { headers: { 'CONFIRM-DELETE': "CONFIRM" } });
    }


    deleteEventAddOn(eventId: string, addOnId: string): any {
        return this.http.delete(environment.api.baseUrl + `/admin/events/${eventId}/addons/${addOnId}`,
            { headers: { 'CONFIRM-DELETE': "CONFIRM" } });
    }

    saveSpeaker(payload: EventSpeaker): Observable<Date> {
        return this.http.post<Date>(`${environment.api.baseUrl}/admin/speakers`, payload);
    }

    publishEventSpeaker(eventId: string, speakerId: string, isPublished: boolean): Observable<Date> {
        return this.http.put<Date>(`${environment.api.baseUrl}/events/${eventId}/speakers/${speakerId}/publish/${isPublished}`, null);
    }

    publishEventSession(eventId: string, sessionId: string, isPublished: boolean): Observable<Date> {
        return this.http.put<Date>(`${environment.api.baseUrl}/events/${eventId}/sessions/${sessionId}/publish/${isPublished}`, null);
    }

    publishEventPrice(eventId: string, priceId: string, isListed: boolean): Observable<Date> {
        return this.http.put<Date>(`${environment.api.baseUrl}/events/${eventId}/prices/${priceId}/listed/${isListed}`, null);
    }

    updateEventSpeakerPosition(eventId: string, speakerId: string, position: number): Observable<Date> {
        return this.http.put<Date>(`${environment.api.baseUrl}/events/${eventId}/speakers/${speakerId}/move/${position}`, null);
    }

    deleteEventSpeaker(eventId: string, speakerId: string): any {
        return this.http.delete(`${environment.api.baseUrl}/admin/events/${eventId}/speakers/${speakerId}`,
            { headers: { 'CONFIRM-DELETE': "CONFIRM" } });
    }

    deleteSpeaker(speakerId: string): any {
        return this.http.delete(`${environment.api.baseUrl}/admin/speakers/${speakerId}`,
            { headers: { 'CONFIRM-DELETE': "CONFIRM" } });
    }

    addSpeakerToEvent(eventId: string, speakerId: string): Observable<EventSpeaker[]> {
        return this.http.post<EventSpeaker[]>(`${environment.api.baseUrl}/admin/events/${eventId}/speakers/${speakerId}`, null);
    }

    removeSpeakerFromEvent(eventId: string, speakerId: string) {
        return this.http.delete(`${environment.api.baseUrl}/admin/events/${eventId}/speakers/${speakerId}`);
    }


    updateEventRibbon(eventId: string, payload: EventRibbon): Observable<Date> {
        return this.http.post<Date>(environment.api.baseUrl + `/admin/events/${eventId}/ribbons`, payload);
    }

    deleteEventRibbon(eventId: string, ribbonId: string) {
        return this.http.delete(environment.api.baseUrl + `/admin/events/${eventId}/ribbons/${ribbonId}`,
            { headers: { 'CONFIRM-DELETE': "CONFIRM" } });
    }

    updateEventAddon(eventId: string, payload: EventAddOn): Observable<Date> {
        return this.http.post<Date>(environment.api.baseUrl + `/admin/events/${eventId}/addons`, payload);
    }

    saveEventAlertSettings(eventId: string, payload: EventAlertSettings): Observable<EventAlertSettings> {
        return this.http.put<EventAlertSettings>(environment.api.baseUrl + `/admin/events/${eventId}/alert-settings`, payload);
    }

    saveEventAlertRecipient(eventId: string, payload: AlertRecipient): Observable<any> {
        return this.http.post<any>(environment.api.baseUrl + `/admin/events/${eventId}/alert-settings/${payload.eventAlertSettingsId}/recipients`, payload);
    }

    deleteEventAlertRecipient(eventId: string, payload: AlertRecipient): Observable<any> {
        return this.http.delete<any>(environment.api.baseUrl + `/admin/events/${eventId}/alert-settings/${payload.eventAlertSettingsId}/recipients/${payload.recipientId}`);
    }

    copyEventAlertSettings(eventId: string, settingsId: number) {
        return this.http.post<EventAlertSettings>(environment.api.baseUrl + `/admin/events/${eventId}/alert-settings/${settingsId}/copy`, null);
    }

    removeAttendeeFromAttribute(badgeId: string, attributeId: string): Observable<any> {
        return this.http.delete<any>(environment.api.baseUrl + `/attendees/${badgeId}/attributes/${attributeId}`);
    }

    addAttendeesToAttribute(attributeId: string, badgeIds: string[]): Observable<any> {
        return this.http.post<any>(environment.api.baseUrl + `/admin/attributes/${attributeId}/attendees`, badgeIds);
    }

    removeAttendeeFromRibbon(badgeId: string, ribbonId: string): Observable<any> {
        return this.http.delete<any>(environment.api.baseUrl + `/attendees/${badgeId}/ribbons/${ribbonId}`);
    }

    addAttendeesToRibbon(ribbonId: string, badgeIds: string[]): Observable<any> {
        return this.http.post<any>(environment.api.baseUrl + `/admin/ribbons/${ribbonId}/attendees`, badgeIds);

    }

    savePriceGroup(group: PriceGroup): Observable<any> {
        return this.http.post<any>(environment.api.baseUrl + `/admin/price-groups`, group);
    }

    deletePriceGroup(groupId: string) {
        return this.http.delete<any>(environment.api.baseUrl + `/admin/price-groups/${groupId}`);
    }

    //#endregion
}
