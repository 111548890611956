import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ScriptLoaderService } from "@ynet-core/services/script-loader.service";
import { FeatureRoutingModule } from './features/feature-routing.module';
import { FeatureComponent } from './features/feature.component';
import { SharedModule } from './shared/shared.module';
import { StoreModule } from '@ngrx/store';
import { reducers, metaReducers } from './app.reducer';
import { Select2Module } from '@ynet-core/select2/ng2-select2';
import { NgxDatatableModule } from '@swimlane/ngx-datatable'; // for ngx-datatable
import { NgxElectronModule } from 'ngx-electron';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '@ynet-environments/environment';
import { UiEffects } from './shared/store/ui.effects';
import { EventService } from '@ynet-core/services/event.service';
import { UserService } from '@ynet-core/services/user.service';
import { ToastrModule } from 'ngx-toastr';
import { NgbModule, NgbDateParserFormatter, NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { CustomNgbDateParserFormatter } from './features/settings/ngb-date-parser.component';
import { UserClaimsGuard } from './core/guards/user-claims.guard';
import { IpcService } from './core/services/ipc.service';
import { GlobalErrorHandler } from './core/handlers/global-error.handler';
import { SettingsService } from './core/services/settings.service';
import { RegistrationService } from './core/services/registration.service.';
import {PrintingService} from "@ynet-core/services/printing.service";



@NgModule({
    declarations: [
        FeatureComponent,
        AppComponent,
    ],
    imports: [
        Select2Module,
        NgxDatatableModule,
        NgxElectronModule,
        BrowserModule,
        HttpClientModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        FeatureRoutingModule,
        NgbModule,
        NgbTypeaheadModule,
        SharedModule,
        StoreModule.forRoot(reducers, { metaReducers }),
        // StoreRouterConnectingModule.forRoot({
        //     stateKey: 'router', // name of reducer key
        // }),
        EffectsModule.forRoot([UiEffects]),  //TODO:  UI Effects?
        StoreDevtoolsModule.instrument({
            maxAge: 25, // Retains last 25 states
            logOnly: environment.production // Restrict extension to log-only mode
        }),
        // AmChartsModule,
        NgxElectronModule,
        ToastrModule.forRoot(),
    ],
    exports: [
        // SharedModule
    ],
    providers: [
        IpcService,
        ScriptLoaderService,
        UserClaimsGuard,
        EventService,
        UserService,
        RegistrationService,
        PrintingService,
        SettingsService,
        { provide: NgbDateParserFormatter, useFactory: () => new CustomNgbDateParserFormatter() },
        { provide: ErrorHandler, useClass: GlobalErrorHandler }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
