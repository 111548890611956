<footer class="m-grid__item	m-footer" appunwraptag="">
	<div class="m-container m-container--fluid m-container--full-height m-page__container">
		<div class="m-stack m-stack--flex-tablet-and-mobile m-stack--ver m-stack--desktop">
			<div class="m-stack__item m-stack__item--left m-stack__item--middle m-stack__item--last">
				<span class="m-footer__copyright float-right">
					Event Management v{{applicationVersion}} &copy;{{currentYear}} by
					<a title="Christians United for Israel" href="https://www.cufi.org" class="m-link">CUFI</a>
				</span>
			</div>
		</div>
	</div>
</footer>

