import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { EventGeneralSettings } from '../../../features/settings/models/eventGeneralSettings.model';



@Component({
    selector: 'edit-location-modal',
    templateUrl: './edit-location-modal.component.html',
    styles: []
}) export class EditLocationModalComponent implements OnInit {
    @Output() newLocationSubmitted = new EventEmitter<string>();


    location: string;


    constructor() { }
    ngOnInit() { }


    show(location: string = '') {
        this.location = location;
        document.getElementById("uxpEditLocationModalButton").click();
    }


    hide() {
        document.getElementById("uxpCloseEditLocationModalButton").click();
    }


    submitForm() {
        this.newLocationSubmitted.emit(this.location);
        this.hide();
    }
}
