import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { IAuthResponse } from '../../shared/models/userProfile.model';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';


@Injectable()
export class AuthHttpIntercepter implements HttpInterceptor {

    constructor(private router: Router) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if (request.url.toLowerCase().indexOf('api.smartystreets.com') > -1) {
            return next.handle(request);
        }

        if (!request.url.endsWith('/login')) {
            const authData: IAuthResponse = JSON.parse(localStorage.getItem(environment.authCookieName)) || {};
            request = request.clone({ headers: request.headers.set('Authorization', `${authData.token_type} ${authData.access_token}`) });
        }

        if (!request.headers.has('Content-Type')) {
            request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
        }

        request = request.clone({ headers: request.headers.set('Api-Key', environment.api.key) });
        request = request.clone({ headers: request.headers.set('Accept', 'application/json') });

        return next.handle(request).pipe(
            tap((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) { }
            }, (err: any) => {

                if (err instanceof HttpErrorResponse) {
                    if (err.status === 401) {
                        this.router.navigate(['login']);
                    }
                }
            }));
    }
}

