export class EventGeneralSettings {
    id: string;
    code: string;
    name: string;
    summary: string;
    description: string;
    location: string;
    email: string;
    start: Date;
    end: Date;
    cancellationPolicy: string;
    confirmationPageHtml: string;
    confirmationEmailHtml: string;
    // sendGridTemplateId: string;
    exitPageUrl: string;
    exitPageText: string;
    bannerImageUrl: string;
    bannerImageText: string;
    status: string;
    statusId: number;


    processPayment: boolean;
    verifyResidenceAddress: boolean;
    importIntoCrm: boolean;
    isPrivate: boolean;

    hasClubs: boolean;

    // settings: EventAdditionalSetting[];

    // event > co > name
    company: string; // KEEP AS CAMEL CASE (API RESTRICTION)


    partnershipEnabled: boolean;
    clubsEnabled: boolean; // settings
    studentEvent: boolean; // vvv settings
    hasPromoCodes: boolean;
    showSessions: boolean;
    givingLevelStartDate: Date;
    givingLevelEndDate: Date;
    defaultAppointmentDate: Date;

    get isNew(): boolean { return this.id == null };
}
