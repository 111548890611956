import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Helpers } from '@ynet-core/helpers';
import { environment } from '@ynet-environments/environment';


@Component({
    selector: "app-footer",
    templateUrl: "./footer.component.html",
    encapsulation: ViewEncapsulation.None,
})
export class FooterComponent implements OnInit {
    applicationVersion: string;
    currentYear: number;


    constructor() {

    }
    ngOnInit() {
        this.currentYear = new Date().getFullYear();
        this.applicationVersion = environment.appVersion;

    }

}
