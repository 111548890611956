import { Injectable } from '@angular/core';
import { IpcRenderer } from 'electron';

@Injectable()
export class IpcService {
    private _ipc: IpcRenderer | undefined = void 0;

    constructor() {
        // @ts-ignore
        if (window.nodeRequire) {
            try {
                // @ts-ignore
                this._ipc = window.nodeRequire('electron').ipcRenderer;
            } catch (e) {
                throw e;
            }
        } else {
            console.warn('Electron\'s IPC was not loaded');
        }
    }

    public isElectronApp(): boolean {
        return !!window.navigator.userAgent.match(/Electron/);
    }

    public on(channel: string, listener: any): void {
        if (!this._ipc) {
            return;
        }
        this._ipc.on(channel, listener);
    }

    public send(channel: string, ...args): void {
        if (!this._ipc) {
            return;
        }
        this._ipc.send(channel, ...args);
    }
}
