<button id="uxpCreateEventModalButton" type="button" [hidden]="true" data-toggle="modal" data-target="#uxpCreateEventModal">
  Show Modal
</button>

<div class="modal fade" id="uxpCreateEventModal" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <form class="payment-form" novalidate (ngSubmit)="newEventForm.form.valid && submitForm();" #newEventForm="ngForm">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            Create New Event
          </h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">
              &times;
            </span>
          </button>
        </div>
        <div class="modal-body">
          <div class="m-section m-section--last">
              <div class="form-group m-form__group row">
                  <label for="code" class="col-3 col-form-label">
                    Code
                  </label>
                  <div class="col-9">
                      <input class="form-control m-input" type="text" [(ngModel)]="model.code" id="code" name="code" required>
                  </div>
                </div>
                <div class="form-group m-form__group row" [ngClass]="{ 'has-danger': newEventForm.submitted && name.invalid }">
                  <label for="name" class="col-3 col-form-label">
                    Name
                  </label>
                  <div class="col-9">
                    <input class="form-control m-input" type="text" [(ngModel)]="model.name" id="name" name="name" #name="ngModel" required>
                  </div>
                </div>
                <div class="form-group m-form__group row">
                  <label for="location" class="col-3 col-form-label">
                    Location
                  </label>
                  <div class="col-9">
                    <input class="form-control m-input" type="text" [(ngModel)]="model.location" id="location" name="location">
                  </div>
                </div>
                <div class="form-group m-form__group row" [ngClass]="{ 'has-danger': newEventForm.submitted && start.invalid }">
                  <label for="start" class="col-2 col-form-label">
                    Start
                  </label>
                  <div class="col-10">
                    <input class="form-control m-input" type="datetime-local" [(ngModel)]="model.start" name="start" id="start" #start="ngModel" required>
                  </div>
                </div>
                <div class="form-group m-form__group row" [ngClass]="{ 'has-danger': newEventForm.submitted && end.invalid }">
                  <label for="end" class="col-2 col-form-label">
                    End
                  </label>
                  <div class="col-10">
                    <input class="form-control m-input" type="datetime-local" [(ngModel)]="model.end" name="end" id="end" #end="ngModel" required>
                  </div>
                </div>
                <div class="form-group m-form__group">
                  <label for="summary">
                    Summary
                  </label>
                  <textarea class="form-control m-input" name="summary" [(ngModel)]="model.summary" rows="3"></textarea>
                </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="submit" class="btn btn-primary" [disabled]="!newEventForm.valid">Add
            Event</button>
          <button type="button" id="uxpCloseCreateEventModalButton" class="btn btn-secondary"
            data-dismiss="modal">Close</button>
        </div>
      </div>
    </form>
  </div>
</div>
