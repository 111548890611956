import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { IUserProfileModel, IAuthResponse } from '../../shared/models/userProfile.model';
import { AlertModel } from '../../shared/models/alert.model';



@Injectable() export class UserService {
    constructor(private http: HttpClient) { }


    getUserInfo(): Observable<IUserProfileModel> {
        return this.http.get<IUserProfileModel>(`${environment.api.baseUrl}/users/me`);
    }


    login(username: string, password: string): Observable<IAuthResponse> {
        return this.http.post<IAuthResponse>(`${environment.api.baseUrl}/login`, { username, password });
    }


    sendAlert(eventId: string, payload: AlertModel) {
        return this.http.post<IAuthResponse>(`${environment.api.baseUrl}/users/send-alert`, { ...payload, eventId: eventId });
    }
}
